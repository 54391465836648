import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loading/Loader";
import { setToken } from "./Utils/tokenutil";
import { useSocket } from "./context/socketContext";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const adminData = useSelector((state) => state.auth.userData);
  const socket = useSocket();
  async function checkLogin(token) {
    if (token) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/refreshToken`,
          {
            refreshToken: JSON.parse(localStorage.getItem("ad_refresh")),
          }
        );
        setToken(response.data);
        dispatch({ type: "LOGIN", payload: response.data });
        if (socket.connected) {
          console.log("Connected");
          const data = {
            userId: response.data.data._id,
            socketId: socket.id,
          };
          socket.emit("create_connection", data);
        }
        setLoading(false);
      } catch (error) {
        dispatch({ type: "LOGOUT" });
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    const refreshToken = localStorage.getItem("ad_refresh");
    checkLogin(refreshToken);
  }, []);

  if (adminData && socket.connected) {
    window.addEventListener("load", () => {
      socket.emit("disconnectEvent", adminData._id);
    });
  }

  return (
    <>
      {loading ? (
        <section className="w-screen h-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <RouterProvider router={Routes} />
      )}
    </>
  );
}

export default App;
