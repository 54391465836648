import React from "react";

const TableIncome = ({ data,onDeleteCategory }) => {
  return data.length ? (
    <table className="w-[70%] mx-auto  min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
      <thead>
        <tr>
          <th style={headerStyle} className="w-[60px]">
            S.No
          </th>
          <th style={headerStyle}>Name</th>
          <th style={headerStyle}></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <td
                style={descriptionStyle}
                className="text-[12px] md:text-[14px] w-[70px]"
              >
                {index + 1}
              </td>
              <td
                style={descriptionStyle}
                className="text-[12px] md:text-[14px] w-[60%] min-w-max"
              >
                {item.name}
              </td>
              <td
                style={descriptionStyle}
              >
                <button onClick={()=>{onDeleteCategory(item.id)}} className="text-[12px] border border-red-700 sm:text-[14px] px-2 py-1 bg-red-700 text-white transition-all duration-500 ease-out hover:bg-white hover:text-red-700">Delete</button>
              </td>

              {/* <td
                style={descriptionStyle}
                className="text-[12px] md:text-[14px]"
              >
                <select
                  name="status"
                  className="outline-none border border-gray-300 py-1 px-2"
                  id="status"
                >
                  <option value="active">Active</option>
                  <option value="inactive">In-Active</option>
                </select>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p>No income category found</p>
  );
};

export default TableIncome;

const headerStyle = {
  textAlign: "center",
  fontSize: "16px",
  padding: "15px 4px",
  color: "rgba(130, 130, 130, 1)",
  borderBottom: "1px solid #b6b6b6",
};

const descriptionStyle = {
  fontWeight: "500",
  padding: "10px 3px",

  borderBottom: "1px solid #b6b6b6",
};
