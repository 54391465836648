import React, { useState } from "react";
import Modal from "./Modal";
import InputSmall from "../Input/InputSmall";
import { useFormik } from "formik";
import { packageSchema } from "../../Schema";
import SelectInput from "../Input/SelectInput";
import { packageCheckBoxes } from "../../Data/StaticData";
import { useDispatch } from "react-redux";
import { createPackage, updatePackage } from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputWrapper from "../Wrapper/InputWrapper";

const PackageModal = ({ open, handleClose, isEdit, data }) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const dispatch = useDispatch();
  const [itemsError, setItemsError] = useState("");
  const [loading, setLoading] = useState(false);
  const [durationError, setDurationError] = useState("");

  const [packageData, setPackageData] = useState({
    packageName: isEdit ? data.name : "",
    description: isEdit ? data.description : "",
    noOfClients: isEdit ? data.noOfClients : 0,
    included: isEdit ? data.included : [],
    noOfStaff: isEdit ? data.noOfStaff : 0,
    packageType: isEdit ? data?.packageType : "Free",
    amount: isEdit ? data.amount : 0,
    duration: {
      durationType: isEdit ? data.duration.durationType : "month",
      duration: isEdit ? data.duration.duration : 0,
    },
  });

  function handleCheckBox(e) {
    const checkboxValue = e.target.value;
    if (e.target.checked) {
      let updatedCheckBoxList = [...packageData.included, checkboxValue];
      setSelectAllChecked(
        updatedCheckBoxList.length === packageCheckBoxes.length
      );
      setPackageData((preValues) => {
        return {
          ...preValues,
          included: updatedCheckBoxList,
        };
      });
    } else {
      setSelectAllChecked(false);
      setPackageData((preValues) => {
        return {
          ...preValues,
          included: preValues.included.filter((item) => item !== checkboxValue),
        };
      });
    }
  }
  // Select All function
  function handleSelectAllChange(e) {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    if (isChecked) {
      packageData.included = packageCheckBoxes.map((item) => item.value);
    } else {
      packageData.included = [];
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: packageData,
      validationSchema: packageSchema,
      onSubmit: (values, action) => {
        // console.log("package values", values);
        if (packageData.duration.duration == 0) {
          setDurationError("Please select duration of the package");
        } else if (!packageData.included.length) {
          setItemsError("Please select atleast 1 item for package");
        } else {
          setLoading(true);
          values.duration = packageData.duration;
          values.included = packageData.included;
          if (isEdit) {
            dispatch(updatePackage({ data: values, packageId: data._id })).then(
              () => {
                setPackageData({
                  name: "",
                  description: "",
                  amount: "",
                  included: [],
                  duration: {
                    durationType: "month",
                    duration: 0,
                  },
                });
                action.resetForm();
                setLoading(false);
                handleClose();
              }
            );
          } else {
            dispatch(createPackage(values)).then(() => {
              setPackageData({
                name: "",
                description: "",
                amount: "",
                included: [],
                duration: {
                  durationType: "month",
                  duration: 0,
                },
              });
              action.resetForm();
              setLoading(false);
              handleClose();
            });
          }
        }
      },
    });

  return (
    <Modal
      open={open}
      onclick={handleClose}
      headerText={isEdit ? "Update Package" : "Add Package"}
    >
      <InputSmall
        name="packageName"
        id="packageName"
        label="Package Name"
        type="text"
        placeholder="Enter Package Name"
        value={values.packageName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.packageName}
        touch={touched.packageName}
      />
      <InputSmall
        customClasses="!mt-4"
        name="description"
        type="text"
        id="description"
        label="Description"
        placeholder="Write description"
        onChange={handleChange}
        value={values.description}
        onBlur={handleBlur}
        error={errors.description}
        touch={touched.description}
      />
      <InputSmall
        customClasses="!mt-4"
        type="number"
        name="noOfClients"
        id="noOfClients"
        label="No. Of Clients"
        placeholder="Number of clients"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.noOfClients}
        error={errors.noOfClients}
        touch={touched.noOfClients}
      />

      <InputWrapper>
        <div className="flex-grow basis-48">
          <label
            htmlFor="packageType"
            className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
          >
            Package Type
          </label>
          <select
            className="w-full rounded-md  border outline-none py-2 px-2"
            name="packageType"
            id="packageType"
            value={values.packageType}
            onChange={(e) => {
              values.amount = 0;
              handleChange(e);
            }}
            onBlur={handleBlur}
          >
            <option className="text-black" value="Free">
              Free (No payment required )
            </option>
            <option className="text-black" value="Sell">
              Sell (Payment required)
            </option>
          </select>
          {errors.packageType && touched.packageType ? (
            <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
              {errors.packageType}
            </span>
          ) : (
            ""
          )}
        </div>
      </InputWrapper>
      {values.packageType === "Sell" ? (
        <InputSmall
          customClasses="!mt-4"
          type="number"
          name="amount"
          id="price"
          label="Price"
          placeholder="Price of package"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          error={errors.amount}
          touch={touched.amount}
        />
      ) : null}

      {/* <SelectInput/> */}
      <SelectInput
        value={packageData.duration.duration}
        name="duration"
        customClassess="!mt-4"
        error={durationError}
        onChange={(e) => {
          setDurationError("");
          setPackageData((prevPackageData) => ({
            ...prevPackageData,
            duration: {
              durationType: e.target.value === "12" ? "year" : "month",
              duration: parseInt(e.target.value),
            },
          }));
        }}
        options={[
          { label: "Duration", value: "0", disabled: true },
          { label: "Month", value: 1, disabled: false },
          { label: "Six Month ", value: 6, disabled: false },
          { label: "Year", value: 12, disabled: false },
        ]}
      />
      {packageData.included.length
        ? packageData.included.map((item) => {
            // console.log("Items ===>",item);
            if (item.toLowerCase() === "staffs") {
              return (
                <InputSmall
                  customClasses="!mt-4"
                  type="number"
                  name="noOfStaff"
                  id="noOfClients"
                  label="No. Of Staff"
                  placeholder="Number of Staff"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.noOfStaff}
                />
              );
            }
          })
        : null}

      <p className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-3">
        {itemsError}
      </p>
      {/* Slect All */}
      <div className="flex justify-end items-center">
        <input
          name={"selectall"}
          // value={checkbox.value}
          id={"selectall"}
          type="checkbox"
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
        />

        <label
          htmlFor="selectall"
          className="pl-2 capitalize text-[12px] sm:text-[16px] satoshi-500"
        >
          Select All
        </label>
      </div>
      <div className="flex justify-between items-center flex-wrap py-4">
        {packageCheckBoxes.map((checkbox, index) => {
          const isChecked = packageData.included.includes(checkbox.value);
          return (
            <div
              key={index}
              className="w-[130px] sm:w-[200px] flex justify-start items-center my-2"
            >
              {isEdit ? (
                <input
                  name={checkbox.name}
                  value={checkbox.value}
                  id={checkbox.name}
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckBox}
                  className="min-w-[15px] h-[15px]"
                />
              ) : (
                <input
                  name={checkbox.name}
                  value={checkbox.value}
                  id={checkbox.name}
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckBox}
                  className="min-w-[15px] h-[15px]"
                />
              )}

              <label
                htmlFor={checkbox.id}
                className="pl-2 capitalize text-[12px] sm:text-[16px] satoshi-500 "
              >
                {checkbox.label}
              </label>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text={isEdit ? "Update" : "Create"}
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default PackageModal;
