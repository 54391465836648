import React, { useState } from 'react';
import EmailStep from '../../components/PasswordRecoverySteps/EmailStep';
import OtpStep from '../../components/PasswordRecoverySteps/OtpStep';
import NewPassword from '../../components/PasswordRecoverySteps/NewPassword';
import FormLayout from '../../Layouts/FormLayout';
const Steps = {
    1:EmailStep,
    2:OtpStep,
    3:NewPassword
}

const ForgetPassword = () => {
    const [step,setStep] = useState(1);
    const CurrentStep = Steps[step];
    const [values,setValues] = useState({
        email:"",
        userId:"",
        code:""
    })

    function handleNext(){
        if(step===3){
            return;
        }else{
            setStep(step+1);
        }
    }


    function handleBack(){
        if(step===1){
            return;
        }else{
            setStep(step-1);
        }
    }

    return (
        <FormLayout>
            <CurrentStep setStepValues={setValues} stepsValues={values} onNext={handleNext} onBack={handleBack}/>
        </FormLayout>
    );
}

export default ForgetPassword;
