import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputSmall from "../Input/InputSmall";
import InputWrapper from "../Wrapper/InputWrapper";
import CatSelector from "../Input/CatSelector";
import { useFormik } from "formik";
import { categorySchema } from "../../Schema";
import { useDispatch } from "react-redux";
import { addCategory } from "../../Redux/Actions";

const initialState = {
  name: "",
  budgetType: "",
};

const CategoryModal = ({ open, handleModal, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [budgetType, setBudgetType] = useState(data[0]);

  function handlechange(e) {
    const selectedTypeObject = JSON.parse(e.target.value); // Use JSON.parse to convert it back to an object
    setBudgetType(selectedTypeObject);
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: categorySchema,
      onSubmit: (values) => {
        setLoading(true);
        values.budgetType = budgetType.id;
        dispatch(addCategory({ values: values, name: budgetType.name })).then(
          () => {
            window.location.reload();
          }
        );
      },
    });

  return (
    <Modal onclick={handleModal} open={open} headerText="Add Category">
      <InputWrapper customClasses={"!items-start"}>
        <InputSmall
          name="name"
          type="text"
          id="name"
          label="Category Name"
          placeholder="Enter Category Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name}
          touch={touched.name}
        />
      </InputWrapper>
      <InputWrapper>
        <CatSelector
          options={data}
          value={JSON.stringify(budgetType)}
          handleChange={handlechange}
          label="Budget Type"
          name="budgetType"
        />
      </InputWrapper>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text="Add"
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default CategoryModal;
