import React from "react";
import formImage from "../Assets/formImage.png";
import logo from "../Assets/logoBlue.png";

const FormLayout = ({ children }) => {
  return (
    <section className="w-screen min-h-screen flex flex-col gradient-blue-white items-stretch justify-end md:flex-row md:justify-center md:bg-white">
      <div className="bg-white mt-24 rounded-t-[30px] px-2 sm:px-5 h-auto flex justify-center items-center w-screen md:w-1/2 md:min-h-screen md:mt-0 md:rounded-none xl:w-[40%]">
        <div className="w-full p-2 flex justify-center items-center pt-6 gap-8 flex-col">
          <div className="mt-6 pl-3 w-[98%]">
            <img className="w-36 h-10" src={logo} alt="Logo" />
          </div>
          {children}
        </div>
      </div>
      <div className="min-h-screen h-auto hidden md:flex justify-center items-center bg-[--primary] md:w-1/2 xl:w-[60%]">
        <img
          className="md:w-1/2 lg:w-[70%] md:h-[40%] lg:h-[70%]"
          src={formImage}
          loading="lazy"
          alt="form background"
        />
      </div>
    </section>
  );
};

export default FormLayout;
