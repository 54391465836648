const initialState = {
  data:null,
  isInvoked:false,
};

const stripeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_STRIPE_KEY":
      return {
        ...state,
        data: payload.data,
        isInvoked:true
      };
    default:
      return state;
  }
};

export default stripeReducer;
