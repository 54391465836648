import React from "react";

const CardWrapper = ({ extraClasses, children }) => {
  return (
    <div
      className={`w-full flex justify-between items-center py-4 gap-2 flex-wrap  ${extraClasses}`}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
