import * as Yup from "yup";

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} `;
};

const minLengthError = (str, value) => {
  return `${str} must be at least ${value} characters`;
};

const maxLengthError = (str, value) => {
  return `${str} must not exceed ${value} characters`;
};

const stringValidation = (message = "Field is required") =>
  Yup.string().required(message);

export const loginSchema = Yup.object({
  email: stringValidation().email(),
  password: stringValidation(),
  //   .min(6, "Password must be at least 6 characters")
  //   .matches(/[0-9]/, getCharacterValidationError("digit"))
  //   .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
  //   .matches(/[A-Z]/, getCharacterValidationError("uppercase character")),
});

export const packageSchema = Yup.object({
  packageName: stringValidation("Please enter package name")
    .min(3, minLengthError("Package name", 3))
    .max(60, maxLengthError("Package name", 60)),
  description: stringValidation()
    .min(8, minLengthError("Description", 8))
    .max(500, minLengthError("Description", 500)),
  amount: Yup.number().required(),
  noOfClients: Yup.number()
    .required("Field is required")
    .min(1, "Number of Clients must be at least 1"),
});

export const coachSchema = Yup.object({
  firstName: stringValidation(),
  lastName: stringValidation(),
  email: stringValidation().email(),
  password: stringValidation()
    .min(6, "Password must be at least 6 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase character")),
  coachInfo: Yup.object().shape({
    BrandLogo: stringValidation("brandlogo is reuired"),
    name: stringValidation("Brand Name is required"),
    endpoint: stringValidation("Endpoint is required"),
  }),
});

export const categorySchema = Yup.object({
  name: stringValidation().min(2, minLengthError("name", 2)),
});

export const stripeSchema = Yup.object({
  secretKey: stringValidation("Secret key is required"),
  publicKey: stringValidation("Public key is required"),
});
export const emailSchema = Yup.object({
  email: loginSchema.fields.email,
});

export const otpSchema = Yup.object({
  inputCode: Yup.number()
    .typeError("OTP must be a number")
    .max(999999, "6 digit only")
    .min(99999, "6 digit only")
    .required("OTP is required"),
});

export const passwordSchema = Yup.object({
  password: stringValidation("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase character"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase character")),
  confirmPassword: stringValidation("Confirm password is required").oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const updatePasswordSchema = Yup.object({
  currentPassword: stringValidation("Please enter your current password"),
  password: passwordSchema.fields.password,
  confirmPassword: passwordSchema.fields.confirmPassword,
});

export const updateEmailSchema = Yup.object({
  newEmail: stringValidation('Email is required').email().max(50, maxLengthError("Email", 50)),
  currentPassword: stringValidation('Password is required')
})

export const updateCoachProfile = Yup.object({
  firstName: stringValidation(),
  lastName: stringValidation(),
  email: stringValidation().email()
})