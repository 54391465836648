import React, { useState } from 'react';
import Form from '../Forms/Form';
import { useFormik } from 'formik';
import InputRequired from '../Input/InputRequired';
import { emailSchema } from '../../Schema';
import ButtonLoading from '../Buttons/ButtonLoading';
import ButtonLarge from '../Buttons/ButtonLarge';
import { emailVerification } from '../../Redux/Actions';

const initialState={
        email:""
}
const EmailStep = ({onNext,setStepValues}) => {
    const [loading, setLoading] = useState(false);
    const {values,errors,touched,handleBlur,handleChange,handleSubmit} = useFormik({
        initialValues: initialState,
        validationSchema:emailSchema,
        onSubmit:(values,{resetForm})=>{
            setLoading(true);
           emailVerification(values).then((res)=>{
            setStepValues({
                email:values.email,
                userId:res.userid,
                code:res.code
            })
                setLoading(false);
                resetForm();
                onNext();
            }).catch((err)=>{
                console.error(err);
                setLoading(false);
            })
        
        }
    });
    return (
      <Form headerText="Email Verification" description='OTP will be send to your email address. Enter valid email address' onSubmit={handleSubmit}>
          <InputRequired
          label="Email"
          type="email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
          touch={touched.email}
          placeholder="johndoe@gmail.com"
        />
              <div className="mt-2 w-full">
          {loading ? <ButtonLoading /> : <ButtonLarge text="Verify Email" />}
        </div>
        </Form>
    );
}

export default EmailStep;
