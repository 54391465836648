const initialState = {
  data: [],
};

const packageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PACKAGES":
      return {
        ...state,
        data: payload,
      };
    case "INSERT_PACKAGE":
      return {
        ...state,
        data: [...state.data, payload],
      };
    case "UPDATE_PACKAGE":
      const updatedPackages = state.data.map((packageInfo) =>
        packageInfo._id === payload._id
          ? { ...packageInfo, ...payload }
          : packageInfo
      );
      return {
        ...state,
        data: updatedPackages,
      };
    case "DELETE_PACKAGE":
      const filteredPackages = state.data.filter(
        (packageInfo) => packageInfo._id !== payload._id
      );
      return {
        ...state,
        data: filteredPackages,
      };
    default:
      return state;
  }
};

export default packageReducer;
