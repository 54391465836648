import React from "react";

const DashboardFormWrapper = ({ customClasses, children }) => {
  return (
    <div
      className={`w-full bg-[--dashboard-gray] mt-3 pt-2 pb-4 px-3 rounded-md ${customClasses}`}
    >
      {children}
    </div>
  );
};

export default DashboardFormWrapper;
