import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import StripeCard from "../components/Card/StripeCard";
import PaymentTableRow from "../components/Table/PaymentTableRow";
import Loader from "../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../Redux/Actions";
import { isEmpty } from "lodash";
import ButtonSmall from "../components/Buttons/ButtonSmall";

const Payment = () => {
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.auth.userData);
  const transactionData = useSelector((state) => state.transactions.data);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const UsersPerPage = 5;

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setPageNumber(1);
  };

  useEffect(() => {
    // Fetch transactions if data is empty
    if (isEmpty(transactionData)) {
      setLoading(true);
      dispatch(getTransactions(userData._id)).then(() => setLoading(false));
    }
  }, []);

  // Filter based on search query and pagination

  const filteredUsers = transactionData?.filter(
    (user) =>
      user?.user?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${user?.user?.firstName?.toLowerCase()} ${user?.user?.lastName?.toLowerCase()}`.includes(
        searchQuery.toLowerCase()
      )
  );

  // Calculate pagination indexes
  const indexOfLastUser = pageNumber * UsersPerPage;
  const indexOfFirstUser = indexOfLastUser - UsersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / UsersPerPage);

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  return (
    <>
      <HeaderData text="Payment Methods" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <StripeCard />
          {transactionData?.length ? (
            <div className="mt-5 rounded-md mr-2">
              <div className="relative min-w-[100px] mb-2 w-[45%] text-[--gray] bg-white border rounded-md ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-1 sm:pl-2">
                  <button
                    type="submit"
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-3 h-3 sm:w-4 sm:h-4 md:w-6 md:h-6"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </span>
                <input
                  type="search"
                  name="q"
                  className="py-2 text-[10px] sm:text-sm w-[98%] bg-inherit rounded-md pl-6 sm:pl-8 md:pl-10 outline-none text-black"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div className="w-full bg-white px-3 py-3">
                <h5 className="text-[21px] satoshi-700">
                  Transactions History
                </h5>
              </div>
              <div className="w-full overflow-x-auto">
                <table className="w-full min-w-[770px] border-collapse !overflow-x-auto bg-white rounded-md">
                  <thead className="border-t">
                    <tr>
                      <th className="text-left pl-4 pr-2 py-2 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="text-left px-2 py-2 text-[--gray] text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-2 text-[--gray] text-sm">
                        Amount
                      </th>
                      <th className="text-left px-2 py-2 text-[--gray] text-sm">
                        Date
                      </th>
                      <th className="text-left px-2 py-2 text-[--gray] text-sm">
                        Package
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers?.map((transaction, index) => {
                      return <PaymentTableRow key={index} data={transaction} />;
                    })}
                  </tbody>
                </table>
              </div>
              <div className=" mt-3 w-full flex justify-between">
                <div className="flex items-center justify-center">
                  <span className="text-sm satoshi-500 pl-1">
                    {" "}
                    Total Pages : {totalPages}
                  </span>
                </div>
                <div>
                  {pageNumber === 1 ? null : (
                    <ButtonSmall
                      onClick={handlePrevPage}
                      customClasses={`mr-2`}
                      text={"Previous"}
                    />
                  )}
                  {pageNumber === totalPages ? null : (
                    <ButtonSmall onClick={handleNextPage} text={"Next"} />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default Payment;
