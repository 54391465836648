import React, { useRef, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import DashboardFormWrapper from "../components/Wrapper/DashboardFormWrapper";
import InputWrapper from "../components/Wrapper/InputWrapper";
import InputSmall from "../components/Input/InputSmall";
import ButtonSmall from "../components/Buttons/ButtonSmall";
import { FiCamera } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  adminReport,
  updateAdminSettings,
  uploadPhoto,
} from "../Redux/Actions";
import ButtonLoading from "../components/Buttons/ButtonLoading";
import defaultimg from "../Assets/default-profile.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../components/Loading/Loader";
import ChangePasswordModal from "../components/Modal/changePasswordModal";
import UpdateEmail from '../components/Modal/UpdateEmail';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from "react-modal";
import cropImageNow from "../Utils/cropImageNow";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 250
const Settings = () => {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const PrevCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [customError, setCustomError] = useState({
    firstName: "",
    lastName: "",
  });
  const [data, setData] = useState(useSelector((state) => state.auth.userData));
  const [passwordModal, setPasswordModal] = useState(false);
  const [updateEmailModal, setUpdateEmailModal] = useState(false);
  const [userCoachId, setUserCoachId] = useState(null);
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null); // Cropped image result
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal
  const [error, setError] = useState('');
  const [crop, setCrop] = useState({});


  function handleInputs(e) {
    const { name, value } = e.target;
    setCustomError((pre) => {
      return {
        ...pre,
        [name]: "",
      };
    });
    setData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  }

  function handleImage(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setLoading(true);
      uploadPhoto(selectedFile).then((responseData) => {
        data.photo = responseData.data.photos[0];
        setLoading(false);
      });
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    if (!data.firstName && !data.lastName) {
      setCustomError({
        firstName: "Field is required",
        lastName: "Field is required",
      });
    } else if (!data.firstName) {
      setCustomError({
        firstName: "Field is required",
        lastName: "",
      });
    } else if (!data.lastName) {
      setCustomError({
        lastName: "Field is required",
        firstName: "",
      });
    } else {
      setLoading(true);
      // uploadPhoto(selectedFile).then((responseData) => {
      //   data.photo = responseData.data.photos[0];
      //   setLoading(false);
      // });
      if (output) {
        setLoading(true);
        const fileExtension = output.split(';')[0].split('/')[1]; // Extract file extension from base64 data
        const imageFile = dataURLtoFile(output, `profile.${fileExtension}`); // Use the correct file extension

        uploadPhoto(imageFile)
          .then((res) => {
            data.photo = res.data.photos[0];
            setTimeout(() => {
              dispatch(updateAdminSettings(data)).then(() => {
                setLoading(false);
              });
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        dispatch(updateAdminSettings(data)).then(() => {
          setLoading(false);
        });
      }
    }
  }

  const generatePdf = (data, option) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = data;

    // Append the container to the body (it will be removed after PDF generation)
    document.body.appendChild(tempContainer);
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(tempContainer, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.75);
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        if (option === "view") {
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.focus();
          } else {
            alert("Please allow popups for this website");
          }
        } else {
          pdf.save("admin_report.pdf");
        }
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      })
      .finally(() => {
        document.body.removeChild(tempContainer);
      });
  };

  async function handleDownloadOrView(option) {
    setPageLoading(true);
    try {
      const response = await adminReport();
      generatePdf(response.data, option);
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
    }
  }

  function handlePasswordModal() {
    setPasswordModal(!passwordModal);
  }

  function handleEmailModal() {
    setUserCoachId(data?._id)
    setUpdateEmailModal(!updateEmailModal);
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the file type from the base64 URL
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime }); // Use extracted mime type
  };



  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      setSrc(imageUrl);
      setIsModalOpen(true);
    });
    reader.readAsDataURL(selectedFile);

  }

  const onImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop)
  }



  return pageLoading ? (
    <Loader />
  ) : (
    <>
      <div className="w-full">
        <HeaderData text="Profile Setting">
          <button
            onClick={() => {
              handleDownloadOrView("download");
            }}
            className="satoshi-500 px-2 sm:px-4 md:px-7 py-1 md:py-2 text-[12px] sm:text-[14px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
          >
            Download Report
          </button>
          <button
            onClick={() => {
              handleDownloadOrView("view");
            }}
            className="satoshi-500 px-2 sm:px-4 md:px-7 py-1 md:py-2 text-[12px] sm:text-[14px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
          >
            View Report
          </button>
        </HeaderData>
        <DashboardFormWrapper customClasses="!bg-white !mt-2">
          <div className="flex justify-center items-center  p-3">
            <label htmlFor="profile" className="cursor-pointer relative py-3">
              <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                Profile Picture
              </p>
              <div className="w-28 h-28 rounded-full border">
                {
                  output ? (
                    <img
                      className="w-full h-full"
                      src={output}
                      alt="profile "
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="w-full h-full"
                      src={
                        data?.photo
                          ? data?.photo
                          : defaultimg
                      }
                      alt="profile "
                      loading="lazy"
                    />
                  )
                }

              </div>
              <input
                onChange={handleProfileChange}
                type="file"
                id="profile"
                hidden
                accept="image/*"
              />
              <div className="w-12 h-12 text-2xl flex justify-center items-center rounded-full absolute top-[70%] right-[0%] bg-[#2F80ED] text-white">
                <FiCamera />
              </div>
            </label>
          </div>
          <InputWrapper customClasses={"!items-stretch"}>
            <InputSmall
              id="firstName"
              name="firstName"
              value={data?.firstName}
              onChange={handleInputs}
              type="text"
              label="First Name"
              placeholder="John"
              touch={true}
              error={customError.firstName}
            />
            <InputSmall
              id="lastName"
              name="lastName"
              value={data?.lastName}
              onChange={handleInputs}
              type="text"
              label="Last Name"
              placeholder="Doe"
              touch={true}
              error={customError.lastName}
            />
          </InputWrapper>
          <InputWrapper>
            <InputSmall
              id="email"
              name="email"
              value={data.email}
              type="email"
              label="Email"
              placeholder="johndoe@gmail.com"
              disable={true}
            />
          </InputWrapper>

          <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
            {loading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall onClick={handleFormSubmit} text="Update" />
                <ButtonSmall
                  onClick={handlePasswordModal}
                  text="Change Password"
                />
                <ButtonSmall
                  onClick={handleEmailModal}
                  text="Change Email"
                />
              </>
            )}
          </div>
        </DashboardFormWrapper>
      </div>
      {passwordModal && (
        <ChangePasswordModal
          open={passwordModal}
          handleModal={handlePasswordModal}
        />
      )}

      {updateEmailModal && (
        <UpdateEmail
          userCoachId={userCoachId}
          open={updateEmailModal}
          handleModal={handleEmailModal}
        />
      )}

      {/* Crop Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
      >
        <h2 className="text-lg font-bold">Crop Image</h2>
        <div className="py-3">
          <button
            className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => {
              cropImageNow(
                imgRef.current,
                PrevCanvasRef.current,
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                )
              );
              const dataUrl = PrevCanvasRef.current.toDataURL();
              setOutput(dataUrl);
              setIsModalOpen(false)
            }}
          >
            Crop and Save
          </button>
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
        </div>
        {
          error && <p className="text-red-600">{error}</p>
        }
        {src && (
          <ReactCrop
            src={src}
            onImageLoaded={setImage}
            crop={crop}
            onChange={
              (pixelCrop, percentCrop) => setCrop(percentCrop)
            }
            // circularCrop
            keepSelection={true}
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={src}
              alt="imageUpload"
              className=""
              onLoad={onImageLoaded}
            />
          </ReactCrop>
        )}

        {crop && (
          <canvas
            ref={PrevCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}

      </Modal >
    </>
  );
};

export default Settings;
