import React, { useState } from "react";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputPassword from "../Input/InputPassword";
import { updateEmailSchema, updatePasswordSchema } from "../../Schema";
import { changeSelfPassword, checkPassword, updateEmail } from "../../Redux/Actions";
import InputRequired from "../Input/InputRequired";
import { errorToast, successToast } from "../../Utils/Toast";

const UpdateEmail = ({ open, handleModal, userCoachId }) => {
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);

    const initialState = {
        newEmail: "",
        currentPassword: ""
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialState,
            validationSchema: updateEmailSchema,
            onSubmit: (values) => {
                setButtonLoading(true);
                const body = {
                    newEmail: values.newEmail,
                    currentPassword: values.currentPassword,
                    userId: userCoachId,
                };

                dispatch(checkPassword(body.currentPassword)).then((res) => {
                    // Dispatch the action instead of directly calling the function
                    dispatch(updateEmail(body))
                        .then((res) => {
                            setButtonLoading(false);
                            handleModal();
                            // dispatch({
                            //     type: 'UPDATE_COACH_EMAIL',
                            //     payload: {
                            //         id: res.data.data._id,
                            //         email: res.data.data.email
                            //     }
                            // })
                            window.location.reload();
                            successToast('Email updated successfully');
                        })
                        .catch((err) => {
                            console.error('Error updating email:', err);
                            setButtonLoading(false);
                        });
                    setButtonLoading(false);
                    handleModal();
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            errorToast('Incorrect password');
                            setButtonLoading(false);
                        } else {
                            errorToast('Something went wrong');
                        }
                    }
                    console.log('Error:', error);
                    handleModal();
                })


            }
        });

    return (
        <Modal headerText="Update Email" open={open} onclick={handleModal}>
            <InputPassword
                label={"Current Password"}
                id={"currentPassword"}
                name={"currentPassword"}
                onChange={handleChange}
                value={values.currentPassword}
                placeholder={'Enter current password'}
                onBlur={handleBlur}
                touch={touched.currentPassword}
                error={errors.currentPassword}
            />

            <InputRequired
                name="newEmail"
                customClasses={"mt-3"}
                id="newEmail"
                label="New Email"
                placeholder="Enter new email"
                value={values.newEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.newEmail}
                touch={touched.newEmail}
            />

            <div className="flex justify-center items-center gap-4 pt-8">
                {buttonLoading ? (
                    <ButtonLoading />
                ) : (
                    <>
                        <ButtonSmall
                            type="button"
                            text="Cancel"
                            customClasses={"!w-1/2 !bg-white !text-[--primary]"}
                            onClick={handleModal}
                        />
                        <ButtonSmall
                            onClick={handleSubmit}
                            type="button"
                            text="Update email"
                            customClasses={"!w-1/2"}
                        />
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UpdateEmail;
