import React, { useEffect, useState } from "react";
import Loader from "../components/Loading/Loader";
import HeaderData from "../components/Header/HeaderData";
import ProgramCategoryModel from "../components/Modal/ProgramCategoryModel";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchProgramCategories } from "../Redux/Actions";
import DeleteProgramCategory from "../components/Modal/DeleteProgramCategory";

const ProgramCategories = () => {
  let Once = true;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState({
    value: "",
    id: "",
  });
  const [toBeDeleted, setDeletedCategory] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  function handleDeleteModal() {
    setDeleteModal(!deleteModal);
  }

  const dispatch = useDispatch();

  const programCategories = useSelector((state) => state.program.data);

  function handleEdit(value, id) {
    setEditValue({
      value: value,
      id: id,
    });
    setIsEdit(!isEdit);
    setOpen(!open);
  }

  function onCloseEdit() {
    setEditValue({
      value: "",
      id: "",
    });
    setIsEdit(false);
    setOpen(!open);
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(programCategories)) {
        setLoading(true);
        dispatch(fetchProgramCategories()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Courses Categories" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-full overflow-x-auto mt-4">
            {programCategories.length ? (
              <table className="w-[70%] mx-auto  min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
                <thead>
                  <tr>
                    <th style={headerStyle} className="w-[70px]">
                      S.No
                    </th>
                    <th style={headerStyle}>Name</th>
                    <th style={headerStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {programCategories.map((category, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={descriptionStyle}
                          className="text-[12px] md:text-[14px] w-[70px]"
                        >
                          {index + 1}
                        </td>
                        <td
                          style={descriptionStyle}
                          className="text-[12px] md:text-[14px]"
                        >
                          {category.name}
                        </td>
                        <td
                          style={descriptionStyle}
                          className="flex justify-center items-center gap-3 py-2"
                        >
                          <button
                            onClick={() => {
                              handleEdit(category.name, category.id);
                            }}
                            className="py-1 px-3 text-[14px] border border-green-500 text-green-600 transition-all ease-in-out duration-300 hover:bg-green-800 hover:text-white hover:border-green-800"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setDeletedCategory(category.id);
                              handleDeleteModal();
                            }}
                            className="py-1 px-3 text-[14px] border border-red-500 text-red-600 transition-all ease-in-out duration-300 hover:bg-red-800 hover:text-white hover:border-red-800"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>No program category found. Please add category for program.</p>
            )}
          </div>
          <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
            <button
              className="bg-[--primary] transition-all ease-in-out duration-300 hover:bg-white hover:text-[--primary]  text-white  border border-[--primary] font-semibold rounded-md px-6 md:px-12 lg:px-16 py-2 text-[13px] md:text-[14px] lg:text-[16px]"
              onClick={onCloseEdit}
            >
              Add Program Category
            </button>
          </div>
          {open && (
            <ProgramCategoryModel
              open={open}
              isEdit={isEdit}
              updateValue={editValue}
              onClose={onCloseEdit}
            />
          )}

          {deleteModal && (
            <DeleteProgramCategory
              open={deleteModal}
              handleModal={handleDeleteModal}
              data={toBeDeleted}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProgramCategories;

const headerStyle = {
  textAlign: "center",
  fontSize: "16px",
  padding: "15px 4px",
  color: "rgba(130, 130, 130, 1)",
  borderBottom: "1px solid #b6b6b6",
};

const descriptionStyle = {
  fontWeight: "500",
  padding: "10px 3px",
  textAlign: "center",
  borderBottom: "1px solid #b6b6b6",
};
