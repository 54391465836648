import React, { useState } from "react";
import Modal from "./Modal";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import { CiImageOn } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { addCoach, uploadPhoto } from "../../Redux/Actions";
import { useFormik } from "formik";
import { coachSchema } from "../../Schema";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputPassword from "../Input/InputPassword";

const AddCoachModal = ({ open, handleModal }) => {
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [photo, setPhoto] = useState("");

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    coachInfo: {
      BrandLogo: "",
      name: "",
      endpoint: "",
    },
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialState,
    validationSchema: coachSchema,
    onSubmit: (values, action) => {
      setButtonLoading(true);
      if (photo) {
        uploadPhoto(photo).then((res) => {
          if (res) {
            values.coachInfo.BrandLogo = res.data.photos[0];
            dispatch(addCoach(values))
              .then((response) => {
                setButtonLoading(false);
                action.resetForm();
                handleModal();
              })
              .catch((error) => {
                console.error(error);
                setButtonLoading(false);
              });
          }
        });
      } else {
        dispatch(addCoach(values))
          .then((response) => {
            setButtonLoading(false);
            action.resetForm();
            handleModal();
          })
          .catch((error) => {
            console.error(error);
            setButtonLoading(false);
          });
      }
    },
  });

  function handleImage(e) {
    setPhoto(e.target.files[0]);
    setValues((pre) => {
      return {
        ...pre,
        coachInfo: {
          ...pre.coachInfo,
          BrandLogo: e.target.files[0],
        },
      };
    });
  }

  return (
    <Modal headerText="Add Coach" open={open} onclick={handleModal}>
      <InputWrapper customClasses={"!items-start"}>
        <InputSmall
          name="firstName"
          type="text"
          id="firstName"
          label="First Name"
          placeholder="Enter First Name"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.firstName}
          touch={touched.firstName}
        />
        <InputSmall
          name="lastName"
          type="text"
          id="lastName"
          label="Last Name"
          placeholder="Enter Last Name"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.lastName}
          touch={touched.lastName}
        />
      </InputWrapper>
      <InputWrapper customClasses={"!items-start"}>
        <InputSmall
          name="email"
          type="email"
          id="email"
          label="Coach Email"
          placeholder="Enter coach email address"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
          touch={touched.email}
        />

        <InputSmall
          name="coachInfo.name"
          type="text"
          id="name"
          label="Brand Name"
          placeholder="Enter Brand name"
          error={errors.coachInfo && errors.coachInfo.name}
          touch={touched.coachInfo && touched.coachInfo.name}
          onChange={handleChange}
          value={values.coachInfo.name}
        />
      </InputWrapper>
      <InputSmall
        name="coachInfo.endpoint"
        type="text"
        id="brandUrl"
        label="URL"
        customClasses={"!mt-3"}
        placeholder="Brand URL"
        error={errors.coachInfo && errors.coachInfo.endpoint}
        touch={touched.coachInfo && touched.coachInfo.endpoint}
        onChange={handleChange}
        value={values.coachInfo.endpoint}
      />
      <InputPassword
        name="password"
        customClasses={"mt-3"}
        id="password"
        label="Password"
        placeholder="Enter password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password}
        touch={touched.password}
      />

      <div
        className={`${
          touched.coachInfo &&
          touched.coachInfo.BrandLogo &&
          errors.coachInfo &&
          errors.coachInfo.BrandLogo
            ? "border-red-600 text-red-600"
            : "border-blue-800 text-blue-800"
        } w-full min-h-[140px] border-2  border-dashed flex justify-start mt-3 items-center flex-col `}
      >
        <label
          htmlFor="reciept"
          className="cursor-pointer w-full text-[--blue] flex justify-center items-center flex-col bg-[--gray-light] py-6"
        >
          {photo ? (
            <img
              className="w-[100px] h-[100px]"
              src={URL.createObjectURL(photo)}
              alt="profile "
            />
          ) : (
            <>
              <CiImageOn className="text-[65px] " />
              <p className="text-[16px] ">Upload Brand Logo</p>
            </>
          )}
        </label>
        <input
          onChange={handleImage}
          id="reciept"
          accept="image/*"
          type="file"
          hidden
        />
      </div>

      <div className="flex justify-center items-center gap-4 pt-8">
        {buttonLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text="Save"
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddCoachModal;
