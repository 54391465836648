const initialState = {
  data: [],
};

const coachReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_COACHES":
      return {
        ...state,
        data: payload.sort((a, b) => b.joinedAt - a.joinedAt),
      };
    case "Update_Coach":
      return {
        ...state,
        data: state.data.map((coach) =>
          coach._id === payload
            ? { ...coach, blockactive: !coach.blockactive }
            : coach
        ),
      };
    case "UPDATE_COACH_EMAIL":
      return {
        ...state,
        data: state.data.map((coach) =>
          coach._id === payload.id
            ? { ...coach, email: payload.email }
            : coach
        ),
      };
    case "APPROVE_COACH":
      return {
        ...state,
        data: state.data.map((coach) =>
          coach._id === payload ? { ...coach, active: true } : coach
        ),
      };
    case "DELETE_COACH":
      return {
        ...state,
        data: state.data.filter((coach) => coach._id !== payload._id),
      };
    default:
      return state;
  }
};

export default coachReducer;
