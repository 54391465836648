import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layouts/DashboardLayout";
import Dashboard from "../Pages/Dashboard";
import Inbox from "../Pages/Inbox";
import Packages from "../Pages/Packages";
import Payment from "../Pages/Payment";
import Settings from "../Pages/Settings";
import Coach from "../Pages/Coach";
import Messages from "../Pages/Messages";
import { RedirectRoute } from "./RedirectRoute";
import SignIn from "../Pages/Auth/SignIn";
import { PrivateRoute } from "./PrivateRoute";
import BudgetSetting from "../Pages/BudgetSetting";
import ProgramCategories from "../Pages/ProgramCategories";
import CoachDetails from "../Pages/CoachDetails";
import PageNotFound from "../Pages/PageNotFound";
import ForgetPassword from "../Pages/Auth/ForgetPassword";

export default createBrowserRouter([
  {
    path: "/signin",
    element: (
      <RedirectRoute>
        <SignIn />
      </RedirectRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <RedirectRoute>
        <ForgetPassword />
      </RedirectRoute>
    ),
  },
  {
    path: "*",
    element:<PageNotFound/>
  },
  {
    path: "",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/inbox",
        element: <Inbox />,
      },
      {
        path: "/coaches",
        element: <Coach />,
      },
      {
        path: "/coaches/coach-detail/:id",
        element: <CoachDetails />,
      },
      {
        path: "/packages",
        element: <Packages />,
      },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/setting",
        element: <Settings />,
      },
      {
        path: "/budget",
        element: <BudgetSetting />,
      },
      {
        path: "/program-categories",
        element: <ProgramCategories />,
      },
      {
        path: "messages/:to",
        element: <Messages />,
      },
    ],
  },
]);
