import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import coachReducer from "./coachReducer";
import packageReducer from "./packageReducer";
import budgetReducer from "./BudgetReducer";
import programReducer from "./programReducer";
import stripeReducer from "./stripeReducer";
import transactioReducer from "./transactionReducer";
import chatListingReducer from "./chatListingReducer";
import chatAllUser from "./chatAllUser";

const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  coach: coachReducer,
  packages: packageReducer,
  categories: budgetReducer,
  program: programReducer,
  stripe: stripeReducer,
  transactions: transactioReducer,
  chatListing: chatListingReducer,
  allChatUser: chatAllUser,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
