export const coachFilters = [
    {
        label:"All",
        value:"all",
    },
    {
        label:"Active",
        value:"active",
    },
    {
    label:"Blocked",
    value:"blocked",
    },
    {
        label:"Pending",
        value:"pending",
    }

]