import React from "react";

const TablesWrapper = ({ customClasses, children }) => {
  return (
    <div
      className={`w-full flex justify-between items-start flex-col gap-2 py-4 mb-3 ${customClasses}`}
    >
      {children}
    </div>
  );
};

export default TablesWrapper;
