import React, { useState } from "react";
import FormLayout from "../../Layouts/FormLayout";
import Form from "../../components/Forms/Form";
import InputPassword from "../../components/Input/InputPassword";
import InputRequired from "../../components/Input/InputRequired";
import ButtonLarge from "../../components/Buttons/ButtonLarge";
// import ButtonWrapper from "../../components/Wrapper/ButtonWrapper";
// import ButtonImage from "../../components/Buttons/ButtonImage";
// import googleImage from "../../Assets/google.png";
// import facebookImage from "../../Assets/facebook.png";
import FormFooter from "../../components/Forms/FormFooter";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Actions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../../Schema";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import { useSocket } from "../../context/socketContext";

const initialValues = {
  email: "",
  password: "",
  role: "Admin",
};

const SignIn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const socket = useSocket();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: () => {
        setLoading(true);
        dispatch(login(values))
          .then((res) => {
            if (socket.connected) {
              console.log("Connected");
              const data = {
                userId: res.data._id,
                socketId: socket.id,
              };
              socket.emit("create_connection", data);
            }
            setLoading(false);
            navigate(from, { replace: true });
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      },
    });

  return (
    <FormLayout>
      <Form headerText="Welcome back" onSubmit={handleSubmit}>
        <InputRequired
          label="Email"
          type="email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
          touch={touched.email}
          placeholder="johndoe@gmail.com"
        />
        <InputPassword
          id="password"
          name="password"
          label="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
          touch={touched.password}
          placeholder="Enter password"
        />
        <div className="w-full text-right">
          <Link
            to="/forget-password"
            className="text-blue-800 satoshi-500 underline text-[14px] lg:text-[16px] transition-all ease-in-out duration-500 hover:text-blue-400"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="mt-2 w-full">
          {loading ? <ButtonLoading /> : <ButtonLarge text="Sign In" />}
        </div>
        {/* <ButtonWrapper text="Or Sign in with"> */}
        {/* <ButtonImage img={googleImage} text="Sign In with Google" /> */}
        {/* <ButtonImage img={facebookImage} text="Sign In with Facebook" /> */}
        {/* </ButtonWrapper> */}
      </Form>
      {/* <FormFooter text="Don't have account ?" url="/signup" urltext="Sign Up" /> */}
    </FormLayout>
  );
};

export default SignIn;
