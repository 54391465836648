import React from "react";

const ButtonLarge = ({ text, customClasses, type, onClick }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      className={`
        w-full py-3 bg-[--primary]  text-white  border border-[--primary] font-semibold rounded-lg ${customClasses}`}
    >
      {text}
    </button>
  );
};

export default ButtonLarge;
