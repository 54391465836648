import React from "react";

const CatSelector = ({ name, label, value, options, handleChange }) => {
  return (
    <div className="flex-grow basis-52 mt-3">
      <label
        htmlFor={name}
        className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
      >
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        className="w-full py-2 px-2 rounded-md border outline-none"
        onChange={handleChange}
      >
        {options?.map((item) => (
          <option key={item.id} value={JSON.stringify(item)}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CatSelector;
