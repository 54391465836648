import React from "react";
// import { BiEdit } from "react-icons/bi";

const TableExpenses = ({ data,onDeleteCategory,onDeletePurpose,onDeleteSubCategory }) => {
  return (
    <table className="w-full md:w-[90%] mx-auto  min-w-[780px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
      <thead>
        <tr>
          <th
            style={headerStyle}
            className="min-w-max w-[30%] !text-left !pl-12"
          >
            Name
          </th>
          <th style={headerStyle}>Purpose</th>
          <th style={headerStyle}></th>
          {/* <th style={headerStyle} className="w-[100px]"></th> */}
        </tr>
      </thead>
      <tbody>
        {data.map((subcat, subIndex) => {
          return (
            <>
              <tr key={subIndex} className="bg-blue-200">
                <td
                  colSpan={2}
                  className="text-center capitalize satoshi-700 text-[12px] sm:text-[14px] lg:text-[16px] py-3"
                >
                  {subcat.subcategoryName}
                </td>
                <td
                >
                  <button onClick={()=>{onDeleteSubCategory(subcat?.subcategoryId)}} className="text-[10px] border border-red-700 sm:text-[12px] md:text-[16px]  px-2 py-1 bg-red-700 text-white transition-all duration-500 ease-out hover:bg-white hover:text-red-700"> Delete Sub-Category</button>

                </td>
                {/* <td className="text-[12px] md:text-[14px]">
                    <button className="p-2 w-full flex justify-center items-center">
                      <BiEdit className="text-[28px] text-green-800 text-center" />
                    </button>
                  </td> */}
              </tr>
              {subcat.data?.map((dataCatPuprpose, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={descriptionStyle}
                      className="!pl-12 !text-left text-[12px] sm:text-[14px]"
                    >
                      {dataCatPuprpose.categoryName}
                    </td>
                    <td
                      style={descriptionStyle}
                      className="text-[12px] sm:text-[14px]"
                    >
                      {dataCatPuprpose?.purposeName || ""}
                    </td>
                    <td
                style={descriptionStyle}
                className="flex justify-center items-center gap-2"
              >
                <button onClick={()=>{onDeleteCategory(dataCatPuprpose?.categoryId)}} className="text-[10px] border border-red-700 md:text-[12px]  px-2 py-1 bg-red-700 text-white transition-all duration-500 ease-out hover:bg-white hover:text-red-700"> Delete Category</button>
                {
                  dataCatPuprpose?.purposeName ?  <button onClick={()=>{onDeletePurpose(dataCatPuprpose.purposeId)}} className="text-[10px] border border-red-700 md:text-[12px] px-2 py-1 bg-red-700 text-white transition-all duration-500 ease-out hover:bg-white hover:text-red-700"> Delete Purpose</button>:
                  null
                }
               
              </td>
                    {/* <td
                        style={descriptionStyle}
                        className="text-[12px] md:text-[14px]"
                      >
                        <button className="w-full flex justify-center items-center">
                          <BiEdit className="text-[20px] md:text-[24px] text-green-600 text-center" />
                        </button>
                      </td> */}
                  </tr>
                );
              })}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableExpenses;
const headerStyle = {
  textAlign: "center",
  fontSize: "16px",
  padding: "15px 4px",
  color: "rgba(130, 130, 130, 1)",
  borderBottom: "1px solid #b6b6b6",
};

const descriptionStyle = {
  fontWeight: "500",
  padding: "10px 3px",
  borderBottom: "1px solid #b6b6b6",
  textAlign: "center",
  textTransform: "capitalize",
};
