const initialState = {
  data: [],
};

const chatListingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CHAT_LISTING":
      return {
        ...state,
        data: payload.data,
      };
    case "APPEND_MESSAGE":
      return {
        ...state,
        data: state.data.map((chat) =>
          chat?._id?.sender?._id === payload?.resp?.data?.receiver
            ? { ...chat, messages: [...chat.messages, payload.generatedData] }
            : chat
        ),
      };
    case "NEW_MESSAGE":
      return {
        data: state.data.map((chat) =>
          chat?._id?.sender?._id === payload?._id?.sender?._id
            ? { ...chat, messages: [...chat.messages, payload.messages[0]] }
            : chat
        ),
      };
    case "NEW_MESSAGE_READ":
      return {
        data: state.data.map((chat) =>
          chat?._id?.sender?._id === payload?._id?.sender?._id
            ? {
                ...chat,
                messages: [...chat.messages, payload.messages[0]],
                unread: 0,
              }
            : chat
        ),
      };
    case "ADD_NEW_CHAT":
      return {
        ...state,
        data: [...state.data, payload],
      };
    case "DELETE_MESSAGE": {
      return {
        ...state,
        data: state.data.map((chat) =>
          chat?._id?.sender?._id === payload?.senderId
            ? {
                ...chat,
                messages: chat.messages.filter(
                  (message) => message._id !== payload.messageId
                ),
              }
            : chat
        ),
      };
    }
    case "DELETE_CHAT": {
      return {
        ...state,
        data: state.data.filter(
          (chat) => chat?._id?.sender?._id !== payload.id
        ),
      };
    }
    case "REMOVE_MESSAGE": {
      return {
        ...state,
        data: state.data.map((chat) =>
          chat?._id?.sender?._id === payload?._id?.sender?._id
            ? {
                ...chat,
                messages: chat.messages.filter(
                  (message) => message._id !== payload.message
                ),
              }
            : chat
        ),
      };
    }
    default:
      return state;
  }
};

export default chatListingReducer;
