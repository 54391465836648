import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import ButtonIcon from "../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CardWrapper from "../components/Wrapper/CardWrapper";
import PackageCard from "../components/Card/PackageCard";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../Redux/Actions";
import Loader from "../components/Loading/Loader";
import PackageModal from "../components/Modal/PackageModal";
import DeletePackageModal from "../components/Modal/DeletePackageModal";
import AssignPackageModal from "../components/Modal/AssignPackageModal";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Packages = () => {
  let Once = true;
  const query = useQuery();
  const [activePackage, setActivePackage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deletedPackage, setDeletedPackage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const packageInfo = useSelector((state) => state.packages.data);
  const [assignModal, setAssignModal] = useState({
    modal: false,
    data: null,
  });

  function handleModal() {
    setOpenModal(!openModal);
  }

  function handleEdit(packageInfo) {
    setIsEdit(true);
    setEditData(packageInfo);
    handleModal();
  }

  function handleDeleteClose() {
    setDeletedPackage("");
    setDeleteModal(false);
  }

  function closeModal() {
    setIsEdit(false);
    setEditData(null);
    handleModal();
  }

  function openAssignPackage(packageInfo) {
    setAssignModal({
      data: packageInfo,
      modal: true,
    });
  }

  function closeAssignModal() {
    setAssignModal({
      modal: false,
      data: null,
    });
  }

  useEffect(() => {
    if (query.size > 0) {
      setActivePackage(query.get("_id"));
    }
    if (Once) {
      Once = false;
      if (isEmpty(packageInfo)) {
        setloading(true);
        dispatch(getPackages()).then(() => {
          setloading(false);
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Packages">
        <ButtonIcon
          onclick={closeModal}
          icon={<IoMdAdd />}
          text="add a package"
        />
      </HeaderData>
      {openModal && (
        <PackageModal
          open={openModal}
          handleClose={closeModal}
          isEdit={isEdit}
          data={editData ? editData : null}
        />
      )}
      {deleteModal && (
        <DeletePackageModal
          open={deleteModal}
          handleClose={handleDeleteClose}
          data={deletedPackage}
        />
      )}

      {assignModal.modal && (
        <AssignPackageModal
          open={assignModal.modal}
          handleClose={closeAssignModal}
          data={assignModal.data}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <CardWrapper extraClasses={"!justify-start !items-stretch"}>
          {packageInfo?.length > 0 ? (
            packageInfo?.map((packageData, index) => {
              return (
                <PackageCard
                  key={index}
                  active={packageData._id === activePackage}
                  packageName={packageData.name}
                  description={packageData.description}
                  price={
                    packageData?.packageType === "Sell"
                      ? `$ ${packageData.amount}`
                      : "Free"
                  }
                  subscriptionTime={
                    packageData.duration.durationType.toLowerCase() === "year"
                      ? "1 Year"
                      : `${packageData.duration.duration} month`
                  }
                  susbcriptionDetail={packageData.included}
                  onAssign={() => {
                    openAssignPackage(packageData);
                  }}
                  self={true}
                  onEdit={() => {
                    handleEdit(packageData);
                  }}
                  onDelete={() => {
                    setDeletedPackage(packageData._id);
                    setDeleteModal(true);
                  }}
                />
              );
            })
          ) : (
            <p className="text-center w-full text-[14px] md:text-[16px] lg:text-[18px] mt-16">
              No package data found. Please add a package
            </p>
          )}
        </CardWrapper>
      )}
    </>
  );
};

export default Packages;
