import React from "react";

const SelectInput = ({
  options,
  name,
  value,
  onChange,
  customClassess,
  error,
}) => {
  return (
    <div className={`text-[14px] max-w-[200px] ${customClassess}`}>
      <select
        className="py-2 border outline-none rounded-md overflow-hidden pr-8 pl-2 satoshi-500 text-[14px]"
        name={name}
        value={value}
        onChange={onChange}
        id="duration"
      >
        {options ? (
          options?.map((opt, index) => {
            return (
              <option
                key={index}
                className="text-[16px]"
                disabled={opt.disabled}
                value={opt.value}
              >
                {opt.label}
              </option>
            );
          })
        ) : (
          <>
            <option value="">This Month</option>
            <option value="">January</option>
            <option value="">January</option>
            <option value="">January</option>
            <option value="">Decemeber</option>
          </>
        )}
      </select>
      <p className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-3">
        {error}
      </p>
    </div>
  );
};

export default SelectInput;
