const initialState = {
    data:[],
  };
  
  const transactioReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "TRANSACTION_DATA":
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  };
  
  export default transactioReducer;
  