import React, { useState } from "react";
import Modal from "./Modal";
import { useFormik } from "formik";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputPassword from "../Input/InputPassword";
import { passwordSchema } from "../../Schema";
import { changeCoachPassword } from "../../Redux/Actions";

const ChangeUserPasswordModal = ({ open, handleModal, coachId }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const initialState = {
    id: coachId,
    password: "",
    confirmPassword: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: passwordSchema,
      onSubmit: (values) => {
        setButtonLoading(true);
        changeCoachPassword(values).then(() => {
          setButtonLoading(false);
          handleModal();
        });
      },
    });

  return (
    <Modal headerText="Update Password" open={open} onclick={handleModal}>
      <InputPassword
        name="password"
        customClasses={"mt-3"}
        id="password"
        label="Password"
        placeholder="Enter password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password}
        touch={touched.password}
      />
      <InputPassword
        name="confirmPassword"
        customClasses={"mt-3"}
        id="confirmPassword"
        label="confirmPassword"
        placeholder="Enter confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.confirmPassword}
        touch={touched.confirmPassword}
      />

      <div className="flex justify-center items-center gap-4 pt-8">
        {buttonLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text="Save"
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ChangeUserPasswordModal;
