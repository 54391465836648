import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import TableIncome from "../components/Table/TableIncome";
import ButtonSmall from "../components/Buttons/ButtonSmall";
import TableExpenses from "../components/Table/TableExpenses";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  fetchExpenseTableData,
  getBudgetTypes,
} from "../Redux/Actions";
import Loader from "../components/Loading/Loader";
import CategoryModal from "../components/Modal/CategoryModal";
import PurposeModal from "../components/Modal/PurposeModal";
import SubCategoryModal from "../components/Modal/SubCategoryModal";
import DeleteBudgetCategory from "../components/Modal/DeleteBudgetCategory";
import DeleteBudgetPurpose from "../components/Modal/DeleteBudgetPurpose";
import DeleteBudgetSubCategory from "../components/Modal/DeleteBudgetSubCategory";

const BudgetSetting = () => {
  let Once = true;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(
    useSelector((state) =>
      state.categories.types.length ? state.categories.types[0] : null
    )
  );
  const [categoryModal, setCategoryModal] = useState(false);
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [purposeModal, setPurposeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState({
    modal: false,
    id: null,
  });
  const [deletePurpose, setDeletePurpose] = useState({
    modal: false,
    id: null,
  });
  const [deleteSubCategory, setDeleteSubcategory] = useState({
    modal: false,
    id: null,
  });
  const expenseCategoriesTable = useSelector(
    (state) => state.categories.tableExpenses
  );
  const budgetTypes = useSelector((state) => state.categories.types);

  const incomeCategories = useSelector(
    (state) => state.categories.categorydata.income
  );
  const expenseCategories = useSelector(
    (state) => state.categories.categorydata.expense
  );

  function handleCategoryModal() {
    setCategoryModal(!categoryModal);
  }

  function handlePurposeModal() {
    setPurposeModal(!purposeModal);
  }

  function handleSubCategoryModal() {
    setSubCategoryModal(!subCategoryModal);
  }

  async function fetchExpenseCategories(e) {
    if (isEmpty(expenseCategoriesTable)) {
      setLoading(true);
      dispatch(fetchExpenseTableData()).then(() => {
        dispatch(fetchCategories(JSON.parse(e.target.value))).then(() => {
          setLoading(false);
        });
      });
    }
  }

  function load() {
    setLoading(true);
  }

  function unLoad() {
    setLoading(false);
  }

  function handleDeleteCategory(id) {
    setDeleteCategory({
      modal: true,
      id:id,
    });
  }

  function handleDeletePurpose(id) {
    setDeletePurpose({
      modal: true,
      id:id,
    });
  }


  function handleDeleteSubCategory(id) {
    setDeleteSubcategory({
      modal: true,
      id:id,
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(budgetTypes)) {
        setLoading(true);
        dispatch(getBudgetTypes()).then((res) => {
          if (res?.data?.length) {
            setSelectedType(res?.data[0]);
            dispatch(fetchCategories(res?.data[0])).then(() => {
              setLoading(false);
            });
          } else {
            setLoading(false);
          }
        });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <HeaderData text="Budget Setting" />
          {budgetTypes.length > 0 ? (
            <>
              <div className="flex justify-center items-center w-full mt-6 mb-8">
                <select
                  className="outline-none border-b-2 text-xl satoshi-500 border-gray-400 px-4 py-1 min-w-[300px] w-[50%] bg-inherit"
                  name="type"
                  value={JSON.stringify(selectedType)} // Use JSON.stringify to set the object as a string
                  onChange={(e) => {
                    const selectedTypeObject = JSON.parse(e.target.value); // Use JSON.parse to convert it back to an object
                    setSelectedType(selectedTypeObject);
                    fetchExpenseCategories(e);
                  }}
                  id="type"
                >
                  {budgetTypes.map((item) => (
                    <option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full overflow-x-auto">
                {selectedType &&
                selectedType?.name?.toLowerCase() === "income" ? (
                  <TableIncome
                    onDeleteCategory={handleDeleteCategory}
                    data={incomeCategories}
                  />
                ) : expenseCategoriesTable.length ? (
                  <TableExpenses onDeleteSubCategory={handleDeleteSubCategory} onDeletePurpose={handleDeletePurpose} onDeleteCategory={handleDeleteCategory} data={expenseCategoriesTable} />
                ) : null}
              </div>
              <div className="mt-4 w-full pt-4 pb-6 flex justify-center items-center gap-3">
                {selectedType &&
                selectedType?.name?.toLowerCase() === "income" ? (
                  <ButtonSmall
                    onClick={handleCategoryModal}
                    text="Add Category"
                  />
                ) : (
                  <>
                    <ButtonSmall
                      onClick={handleCategoryModal}
                      text="Add Category"
                    />
                    <ButtonSmall
                      onClick={handleSubCategoryModal}
                      text="Add Sub-Category"
                    />
                    <ButtonSmall
                      onClick={handlePurposeModal}
                      text="Add Purpose"
                    />
                  </>
                )}
                <CategoryModal
                  data={budgetTypes}
                  open={categoryModal}
                  handleModal={handleCategoryModal}
                />
                <SubCategoryModal
                  data={expenseCategories}
                  open={subCategoryModal}
                  handleModal={handleSubCategoryModal}
                  onLoad={load}
                  unLoad={unLoad}
                />

                {purposeModal ? (
                  <PurposeModal
                    open={purposeModal}
                    category={expenseCategories}
                    handleModal={handlePurposeModal}
                    onLoad={load}
                    unLoad={unLoad}
                  />
                ) : null}
                {deleteSubCategory.modal ? (
                  <DeleteBudgetSubCategory
                    open={deleteSubCategory.modal}
                    handleClose={() => {
                      setDeleteSubcategory({ modal: false, id: null });
                    }}
                    data={deleteSubCategory.id}
                    onLoad={load}
                    unLoad={unLoad}
                  />
                ) : null}

                {deleteCategory.modal ? (
                  <DeleteBudgetCategory
                    open={deleteCategory.modal}
                    handleClose={() => {
                      setDeleteCategory({ modal: false, id: null });
                    }}
                    data={deleteCategory.id}
                  />
                ) : null}

{deletePurpose.modal ? (
                  <DeleteBudgetPurpose
                    open={deletePurpose.modal}
                    handleClose={() => {
                      setDeletePurpose({ modal: false, id: null });
                    }}
                    data={deletePurpose.id}
                    onLoad={load}
                    unLoad={unLoad}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default BudgetSetting;

