const initialState = {
  data: [],
};

const programReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PROGRAMS":
      return {
        ...state,
        data: payload.data,
      };
    case "INSERT_PROGRAM":
      return {
        ...state,
        data: [...state.data, payload.data],
      };
    case "UPDATE_PROGRAM":
      const { id, name } = payload;
      const updatedData = state.data.map((program) =>
        program.id === id ? { ...program, name } : program
      );
      return {
        ...state,
        data: updatedData,
      };
    case "DELETE_PROGRAM":
      const newData = state.data.filter((program) => program.id !== payload.id);
      return {
        ...state,
        data: newData,
      };
    default:
      return state;
  }
};

export default programReducer;
