import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import {deleteBudgetPurpose, fetchExpenseTableData } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const DeleteBudgetPurpose = ({ open, handleClose, data,onLoad,unLoad }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDelete() {
    setLoading(true);
    deleteBudgetPurpose({ _id: data }).then(() => {
      setLoading(false);
      onLoad();
      dispatch(fetchExpenseTableData()).then(() => {
        handleClose();
        unLoad();
      });
    }).catch(err=>{
      setLoading(false);
      console.error(err);
    });
  }

  return (
    <Modal open={open} onclick={handleClose} headerText="Delete Purpose">
      <p className="text-center satoshi-500 text-[17px]">
        Do you want to delete this purpose ?
      </p>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteBudgetPurpose;
