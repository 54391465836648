import React from "react";

const ButtonSmall = ({ type, onClick, text, disabled,customClasses }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      disabled={disabled}
      className={`bg-[--primary] transition-all ease-in-out duration-300 hover:bg-white hover:text-[--primary]  text-white  border border-[--primary] font-semibold rounded-md px-16 py-2 ${customClasses}`}
    >
      {text}
    </button>
  );
};

export default ButtonSmall;
