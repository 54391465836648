import React from "react";

const InputRequired = ({
  label,
  id,
  placeholder,
  type,
  name,
  value,
  labelStyle,
  onChange,
  onBlur,
  error,
  touch,
}) => {
  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className={`block mb-1 pl-[2px] text-[--gray] capitalize ${labelStyle}`}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block w-full p-2.5 "
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && touch ? (
        <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
          {error}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputRequired;
