import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { deleteCoach } from "../../Redux/Actions";

const DeleteUser = ({ open, handleModal, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDelete() {
    setLoading(true);
    dispatch(deleteCoach({ id: data })).then(() => {
      setLoading(false);
      handleModal();
    });
  }

  return (
    <Modal headerText="Delete Coach" open={open} onclick={handleModal}>
      <div className="text-left w-full">
        <h4 className="satoshi-700">
          Do you want to permanently delete this coach ?{" "}
        </h4>
        <p>
          As you consider the decision to permanently remove this coach, it's
          important to note that all associated client and staff records will
          also be affected. This action is irreversible and cannot be undone.
          Please take a moment to carefully consider the implications before
          proceeding
        </p>
      </div>

      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !border-red-600 !bg-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteUser;
