import React, { useRef, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { LuCopyCheck } from "react-icons/lu";

const InputCopy = ({ value, customClasses }) => {
  const inputRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      if (inputRef.current) {
        await navigator.clipboard.writeText(value);
        setIsCopied(true);
        // Reset the "Copied" state after a short delay
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <div className={`flex-grow basis-48 ${customClasses}`}>
      <label
        htmlFor="url"
        className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
      >
        Brand URL
      </label>
      <div className="flex">
        <input
          type="text"
          name="url"
          id="url"
          value={value}
          ref={inputRef}
          className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-tl-md rounded-bl-md focus:border-gray-800 block w-full px-2.5 py-2 "
          disabled={true}
        />
        <button
          className={`px-3 py-1  cursor-pointer ${
            isCopied ? "bg-green-700 text-white" : "bg-[--primary] text-white"
          }`}
          onClick={handleCopyClick}
          disabled={isCopied}
        >
          {isCopied ? <LuCopyCheck /> : <MdContentCopy />}
        </button>
      </div>
    </div>
  );
};

export default InputCopy;
