import { MdOutlineDashboard } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { PiPackageLight } from "react-icons/pi";
import { AiFillCreditCard, AiTwotoneSetting } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { TfiStatsUp } from "react-icons/tfi";

export const dashboardNavItems = [
  {
    label: "Dashboard",
    url: "/",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Inbox",
    url: "/inbox",
    icon: <FiMail />,
  },
  {
    label: "Coaches",
    url: "/coaches",
    icon: <FaRegUser />,
  },
  {
    label: "Package",
    url: "/packages",
    icon: <PiPackageLight />,
  },
  {
    label: "Payments",
    url: "/payment",
    icon: <AiFillCreditCard />,
  },

  {
    label: "Budget",
    url: "/budget",
    icon: <MdPayment />,
  },
  {
    label: "Course",
    url: "/program-categories",
    icon: <CgNotes />,
  },
  {
    label: "settings",
    url: "/setting",
    icon: <AiTwotoneSetting />,
  },
];

export const packageCheckBoxes = [
  { label: "Modify Budget", value: "Modify Budget", name: "modify_budget" },
  {
    label: "Create Smart Plan",
    value: "Create Smart Plan",
    name: "create_smart_plan",
  },
  {
    label: "Access Smart Plan",
    value: "Access Smart Plan",
    name: "access_smart_plan",
  },
  {
    label: "Modify Smart Plan",
    value: "Modify Smart Plan",
    name: "modify_smart_plan",
  },
  {
    label: "Delete Smart Plan",
    value: "Delete Smart Plan",
    name: "delete_smart_plan",
  },
  {
    label: "Smart Plan Automation",
    value: "Smart Plan Automation",
    name: "smart_plan_automation",
  },
  { label: "Payments", value: "Payments", name: "payments" },
  { label: "Media", value: "Media", name: "media" },
  { label: "Media library", value: "Media library", name: "media_library" },
  {
    label: "Create Programs",
    value: "Create Programs",
    name: "create_programs",
  },
  { label: "Assign Program", value: "Assign Program", name: "assign_program" },
  { label: "Add Clients", value: "Add Clients", name: "add_clients" },
  { label: "Staffs", value: "Staffs", name: "staffs" },
  { label: "Tasks", value: "Tasks", name: "tasks" },
  { label: "Saving", value: "Saving", name: "saving" },
  {
    label: "Add Saving Account",
    value: "Add Saving Account",
    name: "add_saving_account",
  },
  { label: "Goals", value: "Goals", name: "goals" },
  { label: "Manage Goals", value: "Manage Goals", name: "manage_goals" },
  {
    label: "Create new Goals",
    value: "Create new Goals",
    name: "create_new_goals",
  },
  { label: "Debts", value: "Debts", name: "debts" },
  {
    label: "Create a Debt pay-off plan",
    value: "Create a Debt pay-off plan",
    name: "create_a_debt_pay_off_plan",
  },
  { label: "Add new debts", value: "Add new debts", name: "add_new_debts" },
  {
    label: "Net worth calculation",
    value: "Net worth calculation",
    name: "net_worth_calculation",
  },
  {
    label: "Create Net worth",
    value: "Create Net worth",
    name: "create_net_worth",
  },
  { label: "Bill payments", value: "Bill payments", name: "bill_payments" },
  { label: "Add New Bill", value: "Add New Bill", name: "add_new_bill" },
  {
    label: "Remind when bill is due",
    value: "Remind when bill is due",
    name: "remind_when_bill_is_due",
  },
  { label: "Due alerts", value: "Due alerts", name: "due_alerts" },
  { label: "Bill due", value: "Bill due", name: "bill_due" },
  { label: "Meeting", value: "Meeting", name: "meeting" },
  { label: "Scheduling", value: "Scheduling", name: "scheduling" },
  { label: "Messaging", value: "Messaging", name: "messaging" },
  {
    label: "Message from coaches",
    value: "Message from coaches",
    name: "message_from_coaches",
  },
  {
    label: "Message from staff",
    value: "Message from staff",
    name: "message_from_staff",
  },
  {
    label: "Send a new message",
    value: "Send a new message",
    name: "send_a_new_message",
  },
  {
    label: "Add Checking account",
    value: "Add Checking account",
    name: "add_checking_account",
  },
  {
    label: "Add Investment Account",
    value: "Add Investment Account",
    name: "add_investment_account",
  },
  {
    label: "Add Retirement Account",
    value: "Add Retirement Account",
    name: "add_retirement_account",
  },
  {
    label: "Account /manage and delete",
    value: "Account /manage and delete",
    name: "account_manage_and_delete",
  },
  { label: "Reports", value: "Reports", name: "reports" },
  { label: "White Label", value: "White Label", name: "white_label" },
  {
    label: "Retirement Plan",
    value: "Retirement Plan",
    name: "retirement_plan",
  },
  {
    label: "Months to Retirement",
    value: "Months to Retirement",
    name: "months_to_retirement",
  },
  { label: "Next Step", value: "Next Step", name: "next_step" },
  { label: "Programs", value: "Programs", name: "programs" },
  { label: "Profile", value: "Profile", name: "profile" },
  {
    label: "Financial Plan (with coach authorization)",
    value: "Financial Plan (with coach authorization)",
    name: "financial_plan_with_coach_authorization",
  },
];
