const initialState = {
  types: [],
  categorydata: {
    income: [],
    expense: [],
  },
  tableExpenses: [],
  subCategories: [],
};

const budgetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_TYPES":
      return {
        ...state,
        types: payload.data,
      };
    case "FETCH_INCOME_CATEGORIES":
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          income: payload.data,
        },
      };
    case "FETCH_EXPENSE_CATEGORIES":
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          expense: payload.data,
        },
      };
    case "FETCH_TABLE_DATA":
      return {
        ...state,
        tableExpenses: payload.data,
      };
    case "ADD_INCOME_CATEGORY":
      return {
        ...state,
        categorydata: {
          ...state.categorydata,
          income: [...state.categorydata.income, payload.data],
        },
      };
    case "FETCH_SUBCATEGORIES":
      return {
        ...state,
        subCategories: payload.data,
      };
    case "ADD_SUBCATEGORY":
      return {
        ...state,
        subCategories: [...state.subCategories, payload.data],
      };
    default:
      return state;
  }
};

export default budgetReducer;
