import React, { useEffect, useRef, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import InputWrapper from "../components/Wrapper/InputWrapper";
import Avatar from "../components/Avatar/Avatar";
import InputSmall from "../components/Input/InputSmall";
import defaultimg from "../Assets/default-profile.png";
import Loader from "../components/Loading/Loader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllCoaches, updateCoachSetting, uploadPhoto } from "../Redux/Actions/index";
import { isEmpty } from "lodash";
import InputCopy from "../components/Input/InputCopy";
import ButtonSmall from "../components/Buttons/ButtonSmall";
import ChangeUserPasswordModal from "../components/Modal/ChangeUserPasswordModal";
// import UpdateEmail from "../components/Modal/UpdateEmail";
import { FiCamera } from "react-icons/fi";
import { updateCoachProfile } from "../Schema";
import { useFormik } from "formik";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from "react-modal";
import cropProfileImage from "../Utils/cropImageNow";
import ButtonLoading from "../components/Buttons/ButtonLoading";


const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200
const CoachDetails = () => {
  let Once = true;
  const imgRef = useRef(null);
  const PrevCanvasRef = useRef(null);
  const param = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [coachDetail, setCoachDetail] = useState([]);
  const { data } = useSelector((state) => state.coach);
  const [btnLoading, setBtnLoading] = useState(false);
  const [password, setPassword] = useState({
    modal: false,
    coachId: "",
  });
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null); // Cropped image result
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal
  const [error, setError] = useState('');
  const [crop, setCrop] = useState({});

  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    role: "Coach",
  });

  useEffect(() => {
    if (isEmpty(data)) {
      setLoading(true);
      dispatch(getAllCoaches()).then(() => {
        setLoading(false);
      });
    }
  }, [data, coachDetail]);
  // console.log('output', output)

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateCoachProfile,
    onSubmit: (values) => {
      if (output) {
        setBtnLoading(true);
        const fileExtension = output.split(';')[0].split('/')[1]; // Extract file extension from base64 data
        const imageFile = dataURLtoFile(output, `profile.${fileExtension}`); // Use the correct file extension

        uploadPhoto(imageFile)
          .then((res) => {
            values.photo = res.data.photos[0];
            setTimeout(() => {
              dispatch(updateCoachSetting(values, param?.id)).then(() => {
                dispatch(getAllCoaches()).then(() => {
                  setBtnLoading(false);
                })
              });
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setBtnLoading(false);
          });
      } else {
        setBtnLoading(true);
        dispatch(updateCoachSetting(values, param?.id)).then(() => {
          dispatch(getAllCoaches()).then(() => {
            setBtnLoading(false);
          })
        });
      }
    },
  });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the file type from the base64 URL
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime }); // Use extracted mime type
  };

  function handleProfileChange(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } = e.currentTarget;
        // if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
        //   setError('Image is too small, please upload a larger image');
        //   setSrc('');
        //   return;
        // }
      })
      setSrc(imageUrl);
      setIsModalOpen(true);
    });
    reader.readAsDataURL(selectedFile);

  }

  const onImageLoaded = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop)
  }

  function datafromredux(id) {
    const foundData = data.find((item) => item._id === id);
    setValues({
      firstName: foundData?.firstName,
      lastName: foundData?.lastName,
      email: foundData?.email,
      photo: foundData?.photo,
    });
    setCoachDetail(foundData);
  }

  function handleUpdatePassword(status = false, id = null) {
    setPassword({
      modal: status,
      coachId: id,
    });
  }

  // function handleEmailModal(id) {
  //   setUserCoachId(id)
  //   setUpdateEmailModal(!updateEmailModal);
  // }

  useEffect(() => {
    if (data.length) {
      setLoading(true);
      datafromredux(param.id);
      setLoading(false);
    }
  }, [data]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text={"Coach Details"}>
            {/* <ButtonSmall
              onClick={() => handleUpdatePassword(true, coachDetail._id)}
              text="Update Coach Passoerd"
            /> */}
          </HeaderData>
          <div className="bg-white rounded-md pt-4 mt-5 px-3 pb-4">
            <div className="flex justify-center items-center  p-3">
              <label htmlFor="profile" className="cursor-pointer relative py-3">
                <p className="block mb-1 pl-[2px] font-medium text-[14px] capitalize text-center">
                  Profile Picture
                </p>
                <div className=" w-32 h-32 rounded-full border">
                  {output ? (
                    <img
                      className="w-full h-full object-cover"
                      src={output}
                      alt="profile"
                    />
                  ) : (
                    <img
                      className="w-full h-full object-cover"
                      src={
                        values?.photo
                          ? values.photo.startsWith("https://")
                            ? values.photo
                            : process.env.REACT_APP_IMAGE_URL + values.photo
                          : defaultimg
                      }
                      alt="profile"
                    />
                  )}
                </div>
                <input
                  onChange={handleProfileChange}
                  type="file"
                  id="profile"
                  hidden
                  accept="image/*"
                />
                <div className="w-14 h-14 text-2xl flex justify-center items-center rounded-full absolute top-[68%] right-[0%] bg-[#2F80ED] text-white">
                  <FiCamera />
                </div>
              </label>
            </div>
            {/* <img
              src={
                coachDetail?.photo
                  ? process.env.REACT_APP_IMAGE_URL.concat(coachDetail?.photo)
                  : defaultimg
              }
              alt=""
              className="w-16 h-16 object-cover rounded-full "
            /> */}
            <InputWrapper customClasses="!items-stretch">
              <InputSmall
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
                placeholder="John"
                value={values.firstName}
                error={errors.firstName}
                touch={touched.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputSmall
                id="lastName"
                name="lastName"
                type="text"
                label="Last Name"
                placeholder="Doe"
                value={values.lastName}
                error={errors.lastName}
                touch={touched.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputWrapper>

            <InputWrapper>
              <InputSmall
                id="email"
                name="email"
                type={"email"}
                label={"Email"}
                placeholder={"Abdullah@gmail.com"}
                value={values?.email}
                error={errors.email}
                touch={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputWrapper>

            <InputWrapper>
              <InputSmall
                type={"email"}
                label={"Joining Date"}
                placeholder={"July 12, 2018"}
                disable={true}
                value={moment(parseInt(coachDetail?.joinedAt)).format(
                  "DD MMM,YYYY"
                )}
              />
            </InputWrapper>
            <InputWrapper customClasses={""}>
              <InputCopy
                value={`https://www.client.smartfbp.com/signup/${coachDetail?.coachInfo?.endpoint}`}
              />
            </InputWrapper>
            <div className="w-full flex justify-center items-center gap-3 mt-4">
              {
                btnLoading ? (
                  <ButtonLoading />
                ) : (
                  <>
                    <ButtonSmall
                      onClick={() => handleUpdatePassword(true, coachDetail._id)}
                      text="Update Coach Password"
                    />
                    {/* <ButtonSmall
                  onClick={() => {
                    handleEmailModal(coachDetail._id)
                    datafromredux(coachDetail._id)
                  }}
                  text="Change Coach Email"
                /> */}
                    <ButtonSmall
                      // onClick={() => {
                      //   datafromredux(coachDetail._id)
                      // }}
                      onClick={handleSubmit}
                      text="Update"
                    />
                  </>
                )
              }

            </div>

            {coachDetail?.noOfStaff?.length ? (
              <div className="w-full overflow-x-auto mt-3 border ">
                <div className="w-full bg-[--primary] text-white text-center py-2">
                  <h1 className="satoshi-700 text-[14px] sm:text-[16px] md:text-[18px]">
                    Coach Staff
                  </h1>
                </div>
                <table className="bg-white   w-full min-w-[600px] border-collapse table-auto !overflow-x-auto mt-1">
                  <thead>
                    <tr>
                      <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm ">
                        Joining Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("coachDetail?.noOfStaff", coachDetail?.noOfStaff)}
                    {coachDetail?.noOfStaff?.map((staff, index) => (
                      <tr className="text-left border-t" key={index}>
                        <td className="flex justify-start items-center py-2 px-2 pl-3 max-w-max">
                          <Avatar image={staff?.photo} />
                          <div className=" ml-2 flex justify-center items-center flex-col">
                            <h5 className="satoshi-700 text-[12px] capitalize sm:text-[14px]">
                              {staff?.firstName + " " + staff?.lastName}
                            </h5>
                          </div>
                        </td>
                        <td
                          onClick={(e) => {
                            e.target.classList.toggle("!max-w-max");
                          }}
                          className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] text-left max-w-[150px] lg:max-w-[180px] xl:max-w-max text-ellipsis"
                        >
                          {staff?.email}
                        </td>
                        <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
                          {moment(parseInt(staff?.joinedAt)).format(
                            "DD MMM,YYYY"
                          )}
                          {/* 20-02-2024 */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}

            {coachDetail?.noOfClients?.length ? (
              <div className="w-full overflow-x-auto mt-7 border">
                <div className="w-full bg-[--primary] text-white text-center py-2">
                  <h1 className="satoshi-700 text-[14px] sm:text-[16px] md:text-[18px]">
                    Coach Customers
                  </h1>
                </div>

                <table className="bg-white  w-full min-w-[600px] border-collapse table-auto !overflow-x-auto mt-1">
                  <thead>
                    <tr>
                      <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        No. of programs
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Joining Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("coachDetail?.noOfClients", coachDetail?.noOfClients)}
                    {coachDetail?.noOfClients?.map((item, index) => (
                      <tr className="text-left border-t" key={index}>
                        {console.log("item", item)}
                        <td className="flex justify-start items-center py-2 px-2 pl-3">
                          <Avatar image={item?.photo} />
                          <div className=" ml-2 flex justify-center items-center flex-col">
                            <h5 className="satoshi-700 text-[12px] capitalize sm:text-[14px]">
                              {/* Abdullah */}
                              {item?.firstName + " " + item?.lastName}
                            </h5>
                            {/* {/ <p className="text-[14px] text-[--gray] "></p> /} */}
                          </div>
                        </td>
                        <td
                          onClick={(e) => {
                            // console.log("helllo")
                            e.target.classList.toggle("!max-w-max");
                          }}
                          className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] text-left max-w-[100px] text-ellipsis"
                        >
                          {item?.email}
                        </td>
                        <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
                          200
                        </td>
                        <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
                          {moment(parseInt(item.joinedAt)).format(
                            "DD MMM,YYYY"
                          )}
                          {/* 20-02-2024 */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* </div> */}
              </div>
            ) : null}
          </div>
          {password.modal && (
            <ChangeUserPasswordModal
              open={password?.modal}
              handleModal={() => {
                handleUpdatePassword();
              }}
              coachId={password?.coachId}
            />
          )}
          {/* {updateEmailModal && (
            <UpdateEmail
              userCoachId={userCoachId}
              open={updateEmailModal}
              coachDetail={coachDetail}
              handleModal={handleEmailModal}
            />
          )} */}

          {/* Crop Modal */}
          <Modal
            ariaHideApp={false}
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className={`w-[80%] sm:w-[70%] md:w-[50%] mx-auto rounded-md mt-20 bg-white shadow-lg flex flex-col justify-center items-center py-5`}
          >
            <h2 className="text-lg font-bold">Crop Image</h2>
            <div className="py-3">
              <button
                className="mr-4 bg-blue-700 text-white py-2 px-4 rounded-md"
                onClick={() => {
                  cropProfileImage(
                    imgRef.current,
                    PrevCanvasRef.current,
                    convertToPixelCrop(
                      crop,
                      imgRef.current.width,
                      imgRef.current.height
                    )
                  );
                  const dataUrl = PrevCanvasRef.current.toDataURL();
                  console.log("dataUrl", dataUrl);
                  setOutput(dataUrl);
                  setIsModalOpen(false)
                }}
              >
                Crop and Save
              </button>
              <button
                className="bg-blue-700 text-white py-2 px-4 rounded-md"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
            {
              error && <p className="text-red-600">{error}</p>
            }
            {src && (
              <ReactCrop
                src={src}
                onImageLoaded={setImage}
                crop={crop}
                onChange={
                  (pixelCrop, percentCrop) => setCrop(percentCrop)
                }
                // circularCrop={true}
                keepSelection={true}
                aspect={ASPECT_RATIO}
                minWidth={MIN_DIMENSION}
              >
                <img
                  ref={imgRef}
                  src={src}
                  alt="imageUpload"
                  className=""
                  onLoad={onImageLoaded}
                />
              </ReactCrop>
            )}

            {crop && (
              <canvas
                ref={PrevCanvasRef}
                className="mt-4"
                style={{
                  display: "none",
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 150,
                  height: 150,
                }}
              />
            )}

          </Modal >
        </>
      )}
    </>
  );
};

export default CoachDetails;
