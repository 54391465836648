const initialState = {
  data: [],
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DATA":
      return {
        ...state,
        data: payload.data,
      };
    case "INCREMENT_COACH":
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          AllUsersCount: item.AllUsersCount + 1,
          CoachesCount: item.CoachesCount + 1,
        })),
      };

    default:
      return state;
  }
};

export default dashboardReducer;
