import React from "react";
import SelectInput from "../Input/SelectInput";

const TableHeader = ({ text, withSelect, customClasses }) => {
  return (
    <div className="w-full flex justify-between gap-2 items-center py-3">
      <h1 className={`satoshi-700 ${customClasses}`}>{text}</h1>
      {withSelect ? <SelectInput /> : ""}
    </div>
  );
};

export default TableHeader;
