export const getYearsOptions = () => {
  const data = [];
  const currentYear = new Date().getFullYear();
  for (let i = 2021; i <= currentYear; i++) {
    data.push({
      label: i,
      value: i,
    });
  }

  return data;
};
