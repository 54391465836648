import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="w-screen h-screen flex  justify-center items-center px-8">
      <div className=" flex flex-col justify-center items-start w-full sm:w-[80vw] md:w-[60vw] lg:w-[550px]">
        <h1 className=" text-[70px] text-[--gray-dark] sm:text-[100px] satoshi-900">404</h1>
        <h1 className="text-[18px] mb-3 sm:text-[26px] text-[--gray] satoshi-700">
          Oops! This Page Could Not Be Found
        </h1>
        <p className="text-[14px] mb-3 text-[--gray]  sm:text-[18px] sm:text-left">
          Sorry the page you are looking for may have been moved, deleted or possibly never existed
        </p>
        <button className="px-6 py-1 bg-[--primary] rounded-md text-white sm:px-10 sm:py-2 sm:text-[18px]">
          <Link to={JSON.parse(localStorage.getItem("adminLocation"))||"/"}>
          GO BACK
          </Link>
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
