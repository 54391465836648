import React, { useState } from "react";
import Modal from "./Modal";
import InputRequired from "../Input/InputRequired";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useFormik } from "formik";
import { categorySchema } from "../../Schema";
import {
  addProgramCategories,
  updateProgramCategory,
} from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { errorToast } from "../../Utils/Toast";

const ProgramCategoryModel = ({ open, isEdit, updateValue, onClose }) => {
  const [loading, setLoading] = useState();
  const [disabled, setDisabled] = useState(true);
  const initialState = {
    name: isEdit ? updateValue.value : "",
  };

  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: categorySchema,
      onSubmit: (values, action) => {
        setLoading(true);
        if (isEdit) {
          if (updateValue.value.trim() == values.name.trim()) {
            setLoading(false);
            errorToast("Please make some changes or cancel the update");
          } else {
            values.id = updateValue.id;
            dispatch(updateProgramCategory(values)).then(() => {
              action.resetForm();
              setLoading(false);
              onClose();
            });
          }
        } else {
          dispatch(addProgramCategories(values)).then(() => {
            action.resetForm();
            setLoading(false);
            onClose();
          });
        }
      },
    });

  return (
    <Modal headerText="Add Category" open={open} onclick={onClose}>
      <InputRequired
        name="name"
        label="Category Name"
        placeholder="Enter Category Name"
        value={values.name}
        error={errors.name}
        touch={touched.name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={onClose}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text={isEdit ? "Update" : "Add"}
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ProgramCategoryModel;
