import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import CoachTableRow from "../components/Table/CoachTableRow";
import Loader from "../components/Loading/Loader";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoaches } from "../Redux/Actions";
import { coachFilters } from "../Data/Filters";
import SelectInput from "../components/Input/SelectInput";
import ButtonSmall from "../components/Buttons/ButtonSmall";
import DeleteUser from "../components/Modal/DeleteUser";
import ButtonIcon from "../components/Buttons/ButtonIcon";
import AddCoachModal from "../components/Modal/AddCoachModal";
import { IoMdAdd } from "react-icons/io";

const Coach = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const coachData = useSelector((state) => state.coach.data);
  const [deleteUser, setDeleteUser] = useState({
    userId: null,
    status: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const coachesPerPage = 5;
  const [coachModal, setCoachModal] = useState(false);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setPageNumber(1);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);

    setPageNumber(1);
  };

  let filteredCoaches = coachData.filter((coach) => {
    switch (selectedFilter) {
      case "active":
        return coach.blockactive === true && coach.active === true;
      case "blocked":
        return coach.blockactive === false && coach.active === true;
      case "pending":
        return coach.active === false;
      default:
        return true;
    }
  });

  // Filter based on search query
  filteredCoaches = filteredCoaches.filter(
    (coach) =>
      // coach.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      // coach.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      coach.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${coach.firstName.toLowerCase()} ${coach.lastName.toLowerCase()}`.includes(
        searchQuery.toLowerCase()
      )
  );
  // Pagination
  const indexOfLastCoach = pageNumber * coachesPerPage;
  const indexOfFirstCoach = indexOfLastCoach - coachesPerPage;
  const currentCoaches = filteredCoaches.slice(
    indexOfFirstCoach,
    indexOfLastCoach
  );

  const totalPages = Math.ceil(filteredCoaches.length / coachesPerPage);

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  function handleDeleteModal(userId = null, status = false) {
    setDeleteUser({
      status: status,
      userId: userId,
    });
  }

  function handleModal() {
    setCoachModal(!coachModal);
  }

  useEffect(() => {
    if (isEmpty(coachData)) {
      setLoading(true);
      dispatch(getAllCoaches()).then(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      {deleteUser.status && (
        <DeleteUser
          open={deleteUser.status}
          data={deleteUser?.userId}
          handleModal={handleDeleteModal}
        />
      )}
      {coachModal && (
        <AddCoachModal open={coachModal} handleModal={handleModal} />
      )}
      <HeaderData text="Coaches">
        <ButtonIcon
          onclick={handleModal}
          icon={<IoMdAdd />}
          text="add a coach"
        />
      </HeaderData>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center gap-8 mt-2">
            <div className="relative min-w-[100px]  w-[35%] text-[--gray] bg-white border rounded-full">
              <span className="absolute inset-y-0 left-0 flex items-center pl-1 sm:pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-3 h-3 sm:w-4 sm:h-4 md:w-6 md:h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="q"
                className="py-2 text-[10px] sm:text-sm w-[98%] bg-inherit rounded-md pl-6 sm:pl-8 md:pl-10 outline-none text-black"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <SelectInput
              onChange={handleFilterChange}
              options={coachFilters}
              name="filters"
            />
          </div>
          <div className="w-full overflow-x-auto">
            {currentCoaches.length > 0 ? (
              <>
                <table className="w-full  min-w-[750px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
                  <thead>
                    <tr>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Name
                      </th>
                      <th className="px-2 py-4 text-[--gray] text-left text-sm">
                        Email
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm pl-4">
                        No. of Clients
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Joining Date
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm">
                        Package
                      </th>
                      <th className="text-left px-2 py-4 text-[--gray] text-sm"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCoaches.map((ele, index) => {
                      return (
                        <CoachTableRow
                          key={index}
                          data={ele}
                          onDeleteCoach={() => {
                            handleDeleteModal(ele?._id, true);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <div className=" mt-3 w-full flex justify-between">
                  <div className="flex items-center justify-center">
                    <span className="text-sm satoshi-500 pl-1">
                      {" "}
                      Total Pages : {totalPages}
                    </span>
                  </div>
                  <div>
                    {pageNumber === 1 ? null : (
                      <ButtonSmall
                        onClick={handlePrevPage}
                        customClasses={`mr-2`}
                        text={"Previous"}
                      />
                    )}
                    {pageNumber === totalPages ? null : (
                      <ButtonSmall onClick={handleNextPage} text={"Next"} />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p className="text-center text-[14px] md:text-[16px] lg:text-[18px] mt-16">
                No coach registered
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Coach;
