import React from "react";
import { HiOutlineUsers } from "react-icons/hi";
import wave from "../../Assets/wave.png";
import SelectInput from "../Input/SelectInput";

const FluidCard = ({ withDropdown, withGraph, text, value }) => {
  return (
    <div className="p-3 bg-white rounded-lg flex-grow basis-44">
      <div className="flex w-full justify-between items-center">
        <div className="w-[40px] h-[40px] bg-[--gray-light] flex justify-center items-center rounded-full text-base">
          <HiOutlineUsers />
        </div>
        {withDropdown ? <SelectInput /> : ""}
      </div>
      <div className="mt-4 mb-1">
        <p className="text-[14px] sm:text-[16px] md:text-[18px] text-[--gray]">
          {text}
        </p>
      </div>
      <div className="mt-1 mb-1">
        <h1 className="text-[16px] sm:text-[19px] md:text-[23px] lg:text-[27px] satoshi-900 font-[900]">
          {value}
        </h1>
      </div>
      {withGraph ? (
        <div className="w-full h-14 mt-3">
          <img src={wave} className="w-full h-full" alt="expense" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FluidCard;
