import React, { useState } from "react";
// import { HiOutlineDotsVertical } from "react-icons/hi";
import Avatar from "../Avatar/Avatar";
import moment from "moment";
import ButtonLoading from "../Buttons/ButtonLoading";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { approveCoach, updateStatus } from "../../Redux/Actions/index";
import { MdDeleteForever } from "react-icons/md";

const CoachTableRow = ({ data, onDeleteCoach }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);

  function changeCoachStatue() {
    setBtnLoading(true);
    dispatch(updateStatus(data._id)).then(() => setBtnLoading(false));
  }

  function approveCoachRegistration() {
    setBtnLoading(true);
    dispatch(approveCoach(data._id)).then(() => setBtnLoading(false));
  }

  return (
    <tr className="text-left border-t">
      {/* <td className="pl-5">
        <input className="w-[15px] h-[15px]" type="checkbox" />
      </td> */}
      <td className="flex justify-start items-center py-2 px-2">
        <Avatar image={data?.photo} />
        <Link
          to={`coach-detail/${data._id}`}
          className=" ml-2 flex justify-center items-center flex-col"
        >
          <h5 className="satoshi-700 text-[12px] capitalize sm:text-[14px] md:min-w-max underline text-blue-800">
            {data.firstName} {data.lastName}
          </h5>
          {/* <p className="text-[14px] text-[--gray] ">@ijohn_doe</p> */}
        </Link>
      </td>
      <td
        onClick={(e) => {
          e.target.classList.toggle("max-w-max");
        }}
        className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] text-left max-w-[140px] text-ellipsis"
      >
        {data.email}
      </td>

      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-4">
        {data.noOfClients.length}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px]">
        {moment(parseInt(data.joinedAt)).format("ll")}
      </td>
      <td className="px-2 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[16px] pl-4 underline text-blue-700">
        <Link
          to={`/packages?_id=${data?.CoachPackage?.packageId?._id}`}
          className="capitalize"
        >
          {data?.CoachPackage?.packageId?.name || "No package"}
        </Link>
      </td>
      <td>
        {btnLoading ? (
          <ButtonLoading
            customClasses={"!bg-red-600 !py-1 !px-0"}
            spinOnly={true}
          />
        ) : (
          <div className="flex justify-center items-stretch gap-3">
            {!data?.active ? (
              <button
                onClick={approveCoachRegistration}
                className="bg-green-700 text-white rounded-md py-1 px-2"
              >
                Approve
              </button>
            ) : (
              <button
                onClick={changeCoachStatue}
                className="bg-red-700 text-white rounded-md py-1 px-2"
              >
                {data.blockactive ? "Block" : "Unblock"}
              </button>
            )}
            <div
              onClick={onDeleteCoach}
              className="cursor-pointer border border-red-700 text-red-700 rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:bg-red-700 hover:text-white"
            >
              <MdDeleteForever className="text-base md:text-lg lg:text-xl xl:text-2xl" />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default CoachTableRow;
