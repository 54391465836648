import React from "react";

const ButtonIcon = ({ icon, text, onclick }) => {
  return (
    <div>
      <button
        onClick={onclick}
        type="button"
        className="px-3 sm:px-5 md:px-7 py-2 md:py-3 text-[12px] sm:text-[14px] md:text-[16px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
      >
        {icon}
        <span className="text-[10px] sm:text-[12px] font-[600] capitalize">
          {text}
        </span>
      </button>
    </div>
  );
};

export default ButtonIcon;
