import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Cell,
} from "recharts";

const ColumnChart = ({ chartdata }) => {
  let abbreviatedData;
  if (chartdata) {
    abbreviatedData = chartdata.map((item) => {
      const monthName = item.month.split("-")[0]; // Extracting the month name
      return {
        month: monthName.length > 5 ? monthName.substring(0, 3) : monthName,
        amount:item.amount,
      };
    });
  }


  return (
    <ResponsiveContainer width="90%" height={300}>
      <BarChart data={abbreviatedData}>
        <Tooltip />
        <XAxis dataKey="month" fontSize="13px" />
        <YAxis fontSize="12px" dataKey="amount"/>
        <Bar dataKey="amount" fill="rgba(34, 68, 129, 1)"  radius={[8, 8, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ColumnChart;
