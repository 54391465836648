import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import InputSmall from "../Input/InputSmall";
import InputWrapper from "../Wrapper/InputWrapper";
import { useFormik } from "formik";
import { categorySchema } from "../../Schema";
import { useDispatch } from "react-redux";
import CatSelector from "../Input/CatSelector";
import { addSubCategory, fetchExpenseTableData } from "../../Redux/Actions";

const initialState = {
  name: "",
  category: "",
};

const SubCategoryModal = ({ open, handleModal, data, onLoad, unLoad }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(data[0]);

  function handlechange(e) {
    const selectedTypeObject = JSON.parse(e.target.value); // Use JSON.parse to convert it back to an object
    setCategory(selectedTypeObject);
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: categorySchema,
      onSubmit: (values, action) => {
        setLoading(true);
        values.category = category._id;
        dispatch(addSubCategory(values)).then(() => {
          setLoading(false);
          action.resetForm();
          onLoad();
          dispatch(fetchExpenseTableData()).then(() => {
            handleModal();
            unLoad();
          });
        });
      },
    });

  return (
    <Modal onclick={handleModal} open={open} headerText="Add Sub-Category">
      <InputWrapper customClasses={"!items-start"}>
        <InputSmall
          name="name"
          type="text"
          id="name"
          label="Sub-Category Name"
          placeholder="Enter Sub-Category Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name}
          touch={touched.name}
        />
      </InputWrapper>
      <InputWrapper>
        <CatSelector
          options={data}
          value={JSON.stringify(category)}
          handleChange={handlechange}
          label="Category"
          name="category"
        />
      </InputWrapper>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2 !bg-white !text-[--primary]"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleSubmit}
              type="button"
              text="Add"
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default SubCategoryModal;
