import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { deleteBudgetCategory } from "../../Redux/Actions";

const DeleteBudgetCategory = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDelete() {
    setLoading(true);
    deleteBudgetCategory({ _id: data }).then(() => {
      window.location.reload();
    });
  }

  return (
    <Modal open={open} onclick={handleClose} headerText="Delete Category">
      <p className="text-center satoshi-500 text-[17px]">
        Do you want to delete this category ?
      </p>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteBudgetCategory;
