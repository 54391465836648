import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
// import ButtonIcon from "../components/Buttons/ButtonIcon";
// import { IoMdAdd } from "react-icons/io";
import TablesWrapper from "../components/Wrapper/TablesWrapper";
import CardWrapper from "../components/Wrapper/CardWrapper";
import FluidCard from "../components/Card/FluidCard";
// import SelectInput from "../components/Input/SelectInput";
import ColumnChart from "../components/Charts/ColumnChart";
import TableHeader from "../components/Table/TableHeader";
// import ColorBoxWrapper from "../components/Wrapper/ColorBoxWrapper";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { clientsCSV, getDashboardData } from "../Redux/Actions";
import Loader from "../components/Loading/Loader";
import SelectInput from "../components/Input/SelectInput";
import { getYearsOptions } from "../Utils/OptionGenerate";

const Dashboard = () => {
  let Once = true;
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard.data);
  const [loading, setLoading] = useState(false);
  const [year, setyear] = useState(new Date().getFullYear());

  function checkRecord(data) {
    let isData = false;
    data.forEach((item) => {
      if (item.amount > 0) {
        isData = true;
      }
    });
    return isData;
  }

  function handleChange(e) {
    setyear(e.target.value);
    setLoading(true);
    dispatch(getDashboardData(e.target.value)).then(() => {
      setLoading(false);
    });
  }

  async function handleDownloadCSV() {
    try {
      setLoading(true);
      const data = await clientsCSV();
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "clients_programs.csv"; // The file name to be downloaded
      document.body.appendChild(a); // Append the link to the body
      a.click(); // Programmatically click the link to trigger the download
      a.remove(); // Remove the link from the document
      window.URL.revokeObjectURL(url); // Revoke the URL to release memory
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(dashboardData)) {
        setLoading(true);
        const defaultYear = new Date().getFullYear();
        dispatch(getDashboardData(defaultYear)).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Dashboard">
        <div className="flex justify-end items-center gap-3">
          <button
            onClick={() => {
              handleDownloadCSV("view");
            }}
            className="satoshi-500 px-2 sm:px-4 md:px-7 py-1 md:py-2 text-[12px] sm:text-[14px] flex justify-center items-center border rounded-lg gap-1 sm:gap-3 text-white bg-[--primary]"
          >
            Clients CSV
          </button>
          <SelectInput
            value={year}
            onChange={handleChange}
            options={getYearsOptions()}
          />
        </div>
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <TablesWrapper customClasses="lg:flex-row">
          <div className="w-full lg:w-[70%]">
            <CardWrapper extraClasses="!py-0">
              <FluidCard
                text="Total Earning"
                value={`$ ${dashboardData[0]?.TotalEarnings || 0}`}
              />
              <FluidCard
                text="This year Earning"
                value={`$ ${dashboardData[0]?.YearlyEarnings || 0}`}
              />
              <FluidCard
                text="Total Coaches"
                value={dashboardData[0]?.CoachesCount || 0}
              />
            </CardWrapper>

            {/* Chart section  */}

            {dashboardData[0]?.recordsByMonth &&
            checkRecord(dashboardData[0]?.recordsByMonth) ? (
              <div className="py-2 rounded-md w-full bg-white px-4 mt-3">
                <div className="w-full flex justify-between items-center py-2">
                  <div>
                    <h5 className="satoshi-700">Revenue</h5>
                  </div>
                </div>
                <div className="w-full flex justify-center items-center">
                  <ColumnChart chartdata={dashboardData[0]?.recordsByMonth} />
                </div>
              </div>
            ) : null}
          </div>

          {/* Left div start  */}
          <div className="w-full lg:w-[30%] bg-white px-3 pb-4">
            <TableHeader text="Total Users" />
            {/* Circle wrapper  */}
            <div className="relative flex justify-center items-center min-h-[400px]">
              {/* circle  */}
              <div className="z-4 w-[150px] h-[150px] rounded-full bg-[--primary] border border-white text-white flex justify-center items-center flex-col absolute top-[6%] sm:left-[36%] md:left-[40%] lg:left-[22%] 2xl:left-[40%]">
                <h6 className="satoshi-900">
                  {dashboardData[0]?.AllUsersCount || 0}
                </h6>
                <p>Total Users</p>
              </div>

              <div className="z-6 w-[130px] h-[130px] rounded-full bg-[--yellow] border border-white text-white flex justify-center items-center flex-col absolute top-[30%] sm:left-[25%] md:left-[30%] lg:top-[35%] lg:left-[0%] xl:left-[5%] 2xl:left-[27%]">
                <h6 className="satoshi-900">
                  {dashboardData[0]?.ClientsCount || 0}
                </h6>
                <p>Customers</p>
              </div>

              <div className="z-4 w-[120px] h-[120px] rounded-full bg-[--cyan] border border-white text-white flex justify-center items-center flex-col absolute  top-[35%] sm:left-[43%] md:left-[45%] 2xl:top-[37%] 2xl:left-[57%]">
                <h6 className="satoshi-900">
                  {dashboardData[0]?.CoachesCount || 0}
                </h6>
                <p>Coaches</p>
              </div>

              <div className="z-9 w-[100px] h-[100px] rounded-full bg-black border border-white text-white flex justify-center items-center flex-col absolute top-[56%] sm:left-[36%] md:left-[40%] xl:left-[30%] 2xl:left-[43%]">
                <h6 className="satoshi-900">
                  {dashboardData[0]?.StaffCount || 0}
                </h6>
                <p>Staffs</p>
              </div>
            </div>
            <div className="w-full">
              {/* <div className="w-full">
                <select
                  name="month"
                  className="w-full border border-black py-2 text-[17px] px-2 rounded-md"
                >
                  <option value="jan">January</option>
                  <option value="feb">Febuary</option>
                  <option value="jan">January</option>
                </select>
              </div> */}

              {/* <div className="w-full bg-[--gray-light] py-3 mt-3 rounded-md px-5">
                <h5 className="text-sm satoshi-700">
                  Comparison with last month
                </h5>
                <ColorBoxWrapper
                  text="This month"
                  backgroundColor="rgba(34, 68, 129, 1)"
                  coverWidth="80"
                />

                <ColorBoxWrapper
                  text="This month"
                  backgroundColor="#000"
                  coverWidth="30"
                />
              </div> */}
            </div>
          </div>
        </TablesWrapper>
      )}
    </>
  );
};

export default Dashboard;
